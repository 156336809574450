import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { SelectionPermission } from 'store/productManagement/reducer.types';
import useNetworkTarget from 'customHooks/useNetworkTarget';
import { changeNewProduct, hideSecondaryPanel } from 'store/productManagement/reducer';
import { NetworkReferenceOptions } from 'store/userOptions/reducer.types';
import { Label } from 'lib/Label';
import Input from 'lib/Input/Input';
import { CodeNameModel } from 'components/common/types';
import NetworkOptions from 'components/common/NetworkOptions';
import { InputType } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import { useCategoryOptions } from 'components/pages/Planner/PlannerSecondaryPanel/useCategoryOptions';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import { permissionOptions } from 'components/pages/PCM/Products/consts';
import { transformAvailableNetworks } from 'utils/transformAvailableNetworks';
import { isValueRequired } from '../../utils/isValueRequired';
import { isPanelConfirmDisabled } from '../../utils/isPanelConfirmDisabled';
import SelectionPermissionDropdown from '../SelectionPermissionDropdown/SelectionPermissionDropdown';
import { ProductNetworksPanelProps } from './ProductNetworksPanel.types';

const ProductNetworksPanel: React.FC<ProductNetworksPanelProps> = ({ transformedNetworks, fieldName }) => {
  const dispatch = useDispatch();
  const networks = useSelector((state: Store) => state.userOptions.networks);
  const productNetworks = useSelector((state: Store) => state.productManagement.newProduct.networks);
  const savedNetworks = productNetworks?.value ?? [];
  const defaultSelectedPermission =
    permissionOptions.find(({ code }) => code === productNetworks?.selectionPermission) ?? permissionOptions[0];
  const [selectedPermission, setSelectedPermission] = useState<{
    code: SelectionPermission;
    name: string;
  }>(defaultSelectedPermission);
  const [availableNetworks, setAvailableNetworks] = useState<NetworkReferenceOptions>({});

  const {
    onCategoryDirectionSort,
    searchText,
    openCategory,
    onFilterTextChange,
    selectedOptions,
    filteredOptions,
    getNumberOfFilteredOptions,
    sortDirection,
    isOptionSelected,
    onToggleCategory,
    setSelectedOptions,
  } = useCategoryOptions(availableNetworks as Record<string, CodeNameModel[]>, transformedNetworks as CodeNameModel[]);

  const mappedOptions = useMemo(() => {
    return selectedOptions.map((option: NetworkCriteriaType) => ({
      ...option,
    }));
  }, [selectedOptions]);

  const { isToleranceError, isChange, isChangeValid, ...networkTarget } = useNetworkTarget({
    selectedOptions: mappedOptions,
    setSelectedOptions,
    savedNetworks,
  });

  useEffect(() => {
    if (!networks?.length) return;

    setAvailableNetworks(transformAvailableNetworks(networks));
  }, [networks]);

  const onConfirm = (networksToConfirm: NetworkCriteriaType[]): void => {
    dispatch(changeNewProduct({ fieldName, value: networksToConfirm, selectedPermission }));
    dispatch(hideSecondaryPanel());
  };

  return (
    <PlannerSecondaryPanel
      title="Networks"
      isDisabled={
        isToleranceError ||
        !isChangeValid ||
        isPanelConfirmDisabled({ field: productNetworks, selectedPermission, newFieldValue: mappedOptions })
      }
      onSubmit={() => onConfirm(selectedOptions as NetworkCriteriaType[])}
      onCancel={() => dispatch(hideSecondaryPanel())}
      customStyles={{
        left: 'auto',
        right: '22px',
        width: '438px',
        height: 'unset',
        maxHeight: 'unset',
        bottom: '82px',
      }}
    >
      <SelectionPermissionDropdown
        selectedPermission={selectedPermission}
        setSelectedPermission={setSelectedPermission}
      />
      {isValueRequired(selectedPermission.code) && (
        <>
          <div className="my-4">
            <SectionDivider />
          </div>

          <Label label="Select networks" />

          <div className="mb-2">
            <Input
              type={InputType.SEARCH}
              value={searchText}
              placeholder="Search networks"
              onChange={onFilterTextChange}
            />
          </div>

          {searchText?.length >= 3 && isEmpty(filteredOptions) ? (
            <p className="text-center px-5 py-2.5 sub-header-base">There are no results matching your search</p>
          ) : null}

          {!!filteredOptions && (
            <NetworkOptions
              options={filteredOptions}
              selectedOptions={selectedOptions}
              onCategoryDirectionSort={onCategoryDirectionSort}
              setSelectedOptions={setSelectedOptions}
              openCategory={openCategory}
              sortDirection={sortDirection}
              onToggleCategory={onToggleCategory}
              isOptionSelected={isOptionSelected}
              getNumberOfFilteredOptions={getNumberOfFilteredOptions}
              networkTarget={{ isToleranceError, isChange, isChangeValid, ...networkTarget }}
            />
          )}
        </>
      )}
    </PlannerSecondaryPanel>
  );
};

export default ProductNetworksPanel;
