import React from 'react';
import { SelectionPermission } from 'store/productManagement/reducer.types';
import { Label } from 'lib/Label';
import BaseSelect from 'lib/BaseSelect';
import { permissionOptions as defaultPermissionOptions } from 'components/pages/PCM/Products/consts';
import { SelectionPermissionDropdownProps } from './SelectionPermissionDropdown.types';

const SelectionPermissionDropdown: React.FC<SelectionPermissionDropdownProps> = ({
  selectedPermission,
  setSelectedPermission,
  isReadOnly = false,
  permissionOptions = defaultPermissionOptions,
}) => {
  return (
    <>
      <Label label="Selection permissions" />
      <BaseSelect
        dataTestId="product-selection-permission"
        onSelect={(_, value: { code: SelectionPermission; name: string }) => setSelectedPermission(value)}
        name="selection-permission"
        options={permissionOptions}
        selectedValue={selectedPermission}
        classNameWrapper="bg-neutral-50 border !border-solid border-neutral-300"
        isDisabled={isReadOnly}
      />
    </>
  );
};

export default SelectionPermissionDropdown;
