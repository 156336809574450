import { NetworkReferenceOptions, NetworkReferenceResponse } from 'store/userOptions/reducer.types';

export const transformAvailableNetworks = (availableNetworks: NetworkReferenceResponse[]): NetworkReferenceOptions => {
  const transformed: NetworkReferenceOptions = {};

  availableNetworks.forEach(({ name, networks }) => {
    if (!transformed[name]) {
      transformed[name] = [];
    }

    const structuredNetworks = networks.map(({ code, assets }) => ({
      code,
      name: code,
      target: '',
      priority: { code: '1', name: '1' },
      greenTolerance: 5,
      orangeTolerance: 10,
      allocated: 0,
      available: assets,
    }));

    transformed[name].push(...structuredNetworks);
  });

  return transformed;
};
