import cx from 'classnames';
import { formatDate } from 'modules/I18N';
import { isValid } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import SVG from 'react-inlinesvg';
import calendarSvg from 'assets/icons/calendar.svg';
import { CaptionElementProps, NavbarElementProps } from 'react-day-picker';
import DayPicker from 'react-day-picker/DayPicker';
import YearMonthForm from 'components/patterns/YearMonthForm';
import arrowLightLeft from 'assets/icons/arrow_light_left.svg';
import arrowLightRight from 'assets/icons/arrow_light_right.svg';

import './DateTimePicker.pcss';
import { getNoonTimeFromDate } from 'utils/dateFormatUtil';
import { getInputBorderStyle } from 'lib/BaseInput';
import Button, { Color, Size } from 'lib/Button';
import { DateTimePickerProps } from './DateTimePicker.types';

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  value,
  onChange,
  isDisabled = false,
  dataTestId = 'date-time-picker-popup',
  disabledDaysBefore = new Date(),
  gap = 8,
  firstDayOfTheWeek = 1,
  yearsBefore = 0,
  yearsAfter = 3,
  seconds = 0,
  milliseconds = 0,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const dayPickerCalendarRef = useRef<HTMLDivElement>(null);
  const dayPickerRef = useRef<DayPicker>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [month, setMonth] = useState(new Date());

  const isValidDate = isValid(value);

  useEffect(() => {
    if (isValidDate) setMonth(value);
  }, [isOpen, isValidDate]);

  const time = useMemo((): string => {
    if (!isValidDate) return '';

    const hours = value.getHours().toString().padStart(2, '0');
    const minutes = value.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  }, [value, isValidDate]);

  const handleYearMonthChange = (newDate: Date): void => setMonth(newDate);

  const handleDateChange = useCallback(
    (newDate: Date): void => {
      newDate.setHours(
        isValidDate ? value.getHours() : 23,
        isValidDate ? value.getMinutes() : 59,
        seconds,
        milliseconds,
      );

      onChange(newDate);
    },
    [value, isValidDate],
  );

  const handleTimeChange = useCallback(
    (dateTime: string): void => {
      const newDate = new Date(value);

      const [hours, minutes] = dateTime.split(':');

      newDate.setHours(+hours, +minutes, seconds, milliseconds);

      onChange(newDate);
    },
    [value],
  );

  return (
    <div ref={parentRef} data-test-id={dataTestId} className="relative">
      <Popover
        containerClassName="z-20 py-1"
        parentElement={parentRef.current as HTMLElement}
        align="end"
        positions={['bottom', 'top']}
        isOpen={isOpen}
        boundaryElement={document.body}
        onClickOutside={() => setIsOpen(false)}
        reposition
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            className="!px-0"
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="var(--transparent-color)"
            arrowSize={gap}
          >
            <div className="relative bg-neutral-50 shadow-md border border-neutral-300 rounded-xl w-[320px]">
              <div
                className="DateTimePicker flex flex-col items-center"
                data-test-id="date-time-picker-calendar"
                ref={dayPickerCalendarRef}
              >
                <DayPicker
                  ref={dayPickerRef}
                  showOutsideDays
                  firstDayOfWeek={firstDayOfTheWeek}
                  selectedDays={value}
                  disabledDays={{ before: disabledDaysBefore }}
                  month={month}
                  onDayClick={(day) => handleDateChange(day)}
                  captionElement={(props: CaptionElementProps) => (
                    <YearMonthForm
                      date={month}
                      onChange={handleYearMonthChange}
                      localeUtils={props.localeUtils}
                      yearsBefore={yearsBefore}
                      yearsAfter={yearsAfter}
                    />
                  )}
                  navbarElement={(props: NavbarElementProps) => (
                    <div className="absolute w-[296px] flex justify-between">
                      <Button
                        onClick={() => {
                          props.onPreviousClick();
                          setMonth(props.previousMonth);
                        }}
                        svg={arrowLightLeft}
                        color={Color.SECONDARY}
                        classNames="w-10 h-10 flex items-center justify-center z-20"
                        svgClassnames="text-primary w-6 h-6"
                      />
                      <Button
                        onClick={() => {
                          props.onNextClick();
                          setMonth(props.nextMonth);
                        }}
                        size={Size.MEDIUM}
                        svg={arrowLightRight}
                        color={Color.SECONDARY}
                        classNames="w-10 h-10 flex items-center justify-center z-20"
                        svgClassnames="text-primary w-6 h-6"
                      />
                    </div>
                  )}
                />
                <div className="my-4">
                  <input
                    className={cx(
                      getInputBorderStyle({ isDisabled }),
                      'text-sm text-center p-1 outline-none bg-neutral-50',
                    )}
                    type="time"
                    data-test-id="date-time-picker-time-input"
                    onChange={(e) => handleTimeChange(e.target.value)}
                    value={time}
                    disabled={!isValidDate}
                  />
                </div>
              </div>
            </div>
          </ArrowContainer>
        )}
      >
        <button
          className={cx(
            'bg-neutral-50 flex justify-center items-center space-x-2 px-2.5 py-1 sub-header-base border border-gray-300 rounded-md border-neutral-950-opacity-10 shadow-inner-sm ',
            {
              'ring-2 border-1 border-primary-600 ring-primary-600 outline-none': isOpen,
            },
          )}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <SVG className="w-4 h-4 text-neutral-500" src={calendarSvg} />
          <p>{isValidDate ? `${formatDate(value)} ${getNoonTimeFromDate(value)}` : 'Date & Time'}</p>
        </button>
      </Popover>
    </div>
  );
};

export default DateTimePicker;
