import axios, { CancelToken } from 'axios';
import Request from 'axios-request-handler';
import Auth from 'modules/Auth';
import Session from 'modules/Session';
import store from 'store';
import queryString from 'query-string';
import { notifyError } from 'store/notification/reducer';
import { API_RESPONSE_ERROR } from 'consts/apiResponseError';
import { HTTP_METHODS, POLLING_INTERVAL } from 'modules/api/constants';
import { apiErrorHandler, errorHandler } from '../ErrorHandle';

let pendingRequests = 0;

const showLoader = (isShowLoader) => {
  pendingRequests += 1;
  if (isShowLoader) {
    const loaderElement = document.getElementById('loader-container');

    if (loaderElement) {
      loaderElement.style.display = 'block';
    }
  }
};

const hideLoader = () => {
  pendingRequests -= 1;
  if (pendingRequests <= 0) {
    pendingRequests = 0;

    const loaderElement = document.getElementById('loader-container');

    if (loaderElement) {
      loaderElement.style.display = 'none';
    }
  }
};

export const getRequestOptions = ({
  headers: { 'env-id': envId, 'publisher-id': publisherId, ...restHeaders } = {},
  ...restOptions
} = {}) => {
  const { token } = Auth.getData();

  return {
    ...restOptions,
    headers: {
      Authorization: `Bearer ${token}`,
      'env-id': restOptions.envId || Session.getEnvironmentId(),
      ...restHeaders,
      ...(publisherId === null || {}),
    },
  };
};

const handleSuccess = (response, options) => {
  hideLoader();

  const apiErrors = apiErrorHandler(response);
  if (apiErrors.length) {
    const { dispatch } = store;
    dispatch(notifyError({ message: apiErrors, timeout: options.notificationTimeout }));
    const e = new Error(JSON.stringify(response.data));
    e.name = 'customError';
    return Promise.reject(e);
  }

  return response;
};

const handleError = (error, options) => {
  hideLoader();
  if (options?.suppressNetworkErrorHandle && error.message === API_RESPONSE_ERROR.NETWORK_ERROR) {
    return Promise.reject(error);
  }

  if (error.name !== 'customError') {
    // check not backend validation error, it is already handled in success block
    let errorMsgs = apiErrorHandler(error.response);
    if (!errorMsgs.length) {
      errorMsgs = errorHandler(error);
    }

    if (errorMsgs.length) {
      const { dispatch } = store;
      dispatch(notifyError({ message: errorMsgs, timeout: options.notificationTimeout }));
    }
  }

  return Promise.reject(error);
};

const createRequest = (options) => {
  const { method = HTTP_METHODS.GET, cancelable = true } = options;
  const { url, query } = queryString.parseUrl(options.url);

  let cancel;
  const poll = (callback, interval = POLLING_INTERVAL) => {
    showLoader(options.showLoader !== false);

    query.updateStamp = 0;
    const request = new Request(queryString.stringifyUrl({ url, query }), getRequestOptions(options));
    cancel = request.cancel;
    return request
      .poll(interval)
      [method.toLowerCase()]((response) => {
        query.updateStamp = response.data.updateStamp;
        request.setUrl(queryString.stringifyUrl({ url, query }));
        return callback(response);
      })
      .then((response) => handleSuccess(response, options))
      .catch((error) => handleError(error, options));
  };

  const send = () => {
    showLoader(options.showLoader);

    return axios({
      ...getRequestOptions(options),
      url: queryString.stringifyUrl({ url, query }),
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    })
      .then((response) => handleSuccess(response, options))
      .catch((error) => handleError(error, options));
  };

  return {
    send,
    poll,
    cancel: () => {
      if (cancelable && cancel) {
        cancel();
      }
    },
  };
};

export default createRequest;
