import { DealStatus } from '../types/Deal.types';

export enum AggregationFunction {
  AVG = 'avg',
  SUM = 'sum',
}

export enum ReportType {
  SAVED = 'SAVED',
  NOT_SAVED = 'NOT_SAVED',
}

export interface SelectedLine {
  lineDefaultReportType: ReportType;
  lineId: string;
  lineName: string;
  lineStatus?: DealStatus;
}

export interface AllocationReportProps {
  defaultReportType?: ReportType;
  onClose: () => void;
}

export enum MetricType {
  IMPRESSIONS = 'IMPRESSIONS',
  FRAMES = 'FRAMES',
  SOT = 'SOT',
}

export interface MetricOption {
  name: string;
  code: MetricType;
}

export interface RowGroupColumn {
  code: string;
  name: string;
  colId: string;
  field: string;
  isSelected: boolean;
  groupOrder?: number;
}

export type ValueFormatter = (value: number) => string;

export interface AllocationDay {
  date: string;
  hours?: number[];
  total: number;
}

export interface AllocationWeek {
  allocationWeek: number;
  days: AllocationDay[];
  total: number;
}

export interface Frame {
  allocations: AllocationWeek[];
  frameId: string;
  productFormat: {
    name: string | null;
    code: string | null;
  };
  total: number;
  town: {
    name: string | null;
    code: string | null;
  };
}

interface MetaData {
  report: {
    id: string;
    version: number;
    creationDateTime: string;
  };
  deal: {
    id: string;
    startDateTime: string;
    endDateTime: string;
  };
}

export interface AllocationReportData {
  contractVersion: number;
  frames: Frame[];
  endDate: string;
  lineDuration: number;
  metaData: MetaData;
  metricType: MetricType;
  rowGroupAggFunction: AggregationFunction;
  startDate: string;
  total: number;
  totalAllocations: AllocationWeek[];
}
