import closeSvg from 'assets/icons/close.svg';
import cx from 'classnames';
import SVG from 'react-inlinesvg';
import { ChipProps, Size, Color, ChipTheme, ChipSize, TextPriority, Width } from './Chip.types';

const Chip = ({
  label,
  subLabel,
  color = Color.GREY,
  size = Size.MEDIUM,
  width = Width.MEDIUM,
  textPriority = TextPriority.LABEL,
  icon = undefined,
  hasIndicator = false,
  isRemovable,
  onRemove,
  isDisabled,
  dataTestId,
  subLabelClassName,
}: ChipProps): React.ReactElement => {
  return (
    <div
      className={cx('flex items-center group w-fit', width, {
        [ChipTheme[color].wrapperStyles]: label,
        [ChipSize[size].wrapperSize]: label,
      })}
      data-test-id={dataTestId}
    >
      <div
        className={cx(
          ChipSize[size].iconWithLabelSize,
          'py-1 flex justify-center items-center',
          { 'basis-0 flex-grow overflow-hidden': textPriority === TextPriority.SUBLABEL },
          { 'overflow-hidden': textPriority === TextPriority.EQUAL },
        )}
      >
        {!!icon && <SVG className={cx(ChipTheme[color].iconStyles, ChipSize[size].iconSize, 'shrink-0')} src={icon} />}
        {!!label && (
          <p
            className={cx(
              ChipTheme[color].labelStyles,
              ChipSize[size].labelSize,
              'max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis',
            )}
          >
            {label}
          </p>
        )}
      </div>
      {!!subLabel && (
        <div
          className={cx(
            ChipTheme[color].subLabelStyles,
            ChipSize[size].subLabelSize,
            'h-full flex items-center justify-center text-center overflow-auto',
            subLabelClassName,
          )}
        >
          <p className="max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">{subLabel}</p>
        </div>
      )}
      {isRemovable && (
        <button
          aria-label="Remove chip"
          type="button"
          className={cx(
            ChipTheme[color].removeButtonStyles,
            ChipSize[size].removeButtonSize,
            'flex justify-center items-center mx-1.5',
          )}
          onClick={!isDisabled && label ? () => onRemove?.(label) : () => null}
          data-test-id={`chip-remove-${label}`}
        >
          <SVG src={closeSvg} />
        </button>
      )}
      {hasIndicator ? (
        <div
          data-test-id="chip-indicator"
          className={cx(ChipTheme[color].indicatorStyles, 'w-1.5 h-1.5 mr-2 mx-1.5 rounded-full shrink-0')}
        />
      ) : null}
    </div>
  );
};

export default Chip;
