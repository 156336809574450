import SVG from 'react-inlinesvg';
import excludeSvg from 'assets/icons/exclude.svg';
import includeSvg from 'assets/icons/include.svg';

import { CollapsibleListStatsHeaderProps } from './CollapsibleListStatsHeader.types';

const COMMON_STYLE = 'whitespace-nowrap flex items-center gap-1 rounded-full px-2 py-0.5 text-sm';

const Style = {
  EXCLUDED: `${COMMON_STYLE} bg-pinkRed-100 text-pinkRed-800`,
  INCLUDED: `${COMMON_STYLE} bg-green-50 text-green-800`,
  AVAILABLE: `${COMMON_STYLE} bg-primary-50 text-primary-600`,
};

const CollapsibleListStatsHeader: React.FC<CollapsibleListStatsHeaderProps> = ({
  excluded,
  included,
  available = 0,
  availableLengthSuffix = <p>results</p>,
}) => {
  return (
    <div data-test-id="collapsible-list-header" className="flex flex-1 gap-1 justify-start p-2">
      <div data-test-id="collapsible-list-header-available" className={Style.AVAILABLE}>
        {available} {availableLengthSuffix}
      </div>

      {excluded ? (
        <div data-test-id="collapsible-list-header-excluded" className={Style.EXCLUDED}>
          <SVG src={excludeSvg} className="w-4 h-4" /> {excluded}
        </div>
      ) : null}
      {included ? (
        <div data-test-id="collapsible-list-header-included" className={Style.INCLUDED}>
          <SVG src={includeSvg} className="w-4 h-4" /> {included}
        </div>
      ) : null}
    </div>
  );
};

export default CollapsibleListStatsHeader;
