import CollapsibleList from 'lib/CollapsibleList';
import CollapsibleListStatsHeader from 'lib/CollapsibleList/CollapsibleListStatsHeader';
import { FormatLabel, FormatOption } from 'store/userOptions/reducer.types';
import SelectOption from 'lib/SelectOption';
import { shouldDisplayCategoryClearAll } from 'components/pages/Planner/PlannerSections/FiltersContent/utils';
import { FormatOptionsProps } from 'components/common/FormatOptions/FormatOptions.types';
import React from 'react';

export const FormatOptions: React.FC<FormatOptionsProps> = ({
  filteredOptions,
  selectedFormats,
  onCategoryDirectionSort,
  sortDirection,
  onToggleCategory,
  setSelectedOptions,
  getNumberOfFilteredOptions,
  isOptionIncluded,
  isOptionSelected,
  onOptionIncludeExcludeChange,
  openCategory,
  includedOptions,
  excludedOptions,
}) => {
  return (
    <>
      {Object.keys(filteredOptions).map((category: FormatOption) => {
        const selectedOptions = selectedFormats.filter(
          (dealLineFormat) => dealLineFormat.category !== FormatLabel[category],
        );

        return (
          <CollapsibleList
            label={FormatLabel[category]}
            isSortVisible
            onSort={onCategoryDirectionSort}
            sortDir={sortDirection}
            isOpen={category === openCategory}
            onToggle={() => onToggleCategory(category)}
            isClearAllVisible={shouldDisplayCategoryClearAll(selectedFormats, FormatLabel[category])}
            onClearAll={() => setSelectedOptions(selectedOptions)}
            key={`format-category-${FormatLabel[category]}`}
            headerComponent={
              <CollapsibleListStatsHeader
                available={filteredOptions?.[category].length}
                included={getNumberOfFilteredOptions(category, includedOptions)}
                excluded={getNumberOfFilteredOptions(category, excludedOptions)}
              />
            }
          >
            {filteredOptions?.[category].map(({ code, name }) => {
              return (
                <SelectOption
                  value={{
                    code,
                    name,
                    include: isOptionIncluded(code, category),
                  }}
                  key={`select-category-${code}`}
                  isSelected={isOptionSelected(code, category)}
                  isDisabled={false}
                  isMultiple
                  hasCheckbox={false}
                  isIncludeExcludeAlwaysVisible
                  onIncludeExcludeChange={(value) =>
                    onOptionIncludeExcludeChange({
                      ...value,
                      category: FormatLabel[category],
                    })
                  }
                  classNames="!m-0"
                >
                  <p className="max-w-[250px] truncate">{name}</p>
                </SelectOption>
              );
            })}
          </CollapsibleList>
        );
      })}
    </>
  );
};
