import { InputContainer } from 'components/common/Deal/CampaignHeader/CampaignDrawer/Details/utils';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import { ShadowStyle } from 'consts/shadow';
import BaseSelect from 'lib/BaseSelect';
import { Padding } from 'lib/BaseSelect/BaseSelect.types';
import { Label } from 'lib/Label';

export const FolderInput: React.FC = () => {
  return (
    <InputContainer>
      <Label id="folder" theme={InputTheme.FLAT_GRAY} label="Folder" />
      <BaseSelect
        onSelect={() => undefined}
        name="Folder"
        dataTestId="select-folder"
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        padding={Padding.NONE}
        shadow={ShadowStyle.MEDIUM}
        shape={InputShape.ROUNDED}
        options={[]}
        classNameWrapper="bg-neutral-50 border !border-solid border-neutral-300"
      />
    </InputContainer>
  );
};
