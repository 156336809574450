import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SOURCE_SYSTEM } from 'consts/sourceSystem';
import { Label } from 'lib/Label';
import { CustomBaseSelectProps } from 'lib/BaseSelect/BaseSelect.types';
import { changeDealInfo } from 'store/dealManagement/actions';
import { InputTheme } from 'components/common/types/Input.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { Store } from 'components/common/types/Store.types';
import { useLoadLookup } from 'components/common/FilterArea/Filters/useLoadLookup';
import { Advertiser, BrandWithProductCategory, DealStatus } from 'components/common/types/Deal.types';
import { CodeNameModel } from 'components/common/types';
import { useDealFormReadOnly } from 'customHooks/useDealFormReadOnly';
import usePrevious from 'customHooks/usePrevious';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import { updateOrganisations } from '../utils';

type SelectedBrand = BrandWithProductCategory & {
  advertisers: CodeNameModel[];
};

interface SelectBrandProps extends Pick<CustomBaseSelectProps, 'theme' | 'size' | 'padding' | 'shadow' | 'shape'> {
  brands: BrandWithProductCategory[];
  setAdvertisers: (value: React.SetStateAction<Advertiser[]>) => void;
  setBrands: (value: React.SetStateAction<BrandWithProductCategory[]>) => void;
  dataTestId?: string;
  placeholder?: string;
  withLabel?: boolean;
}

export const SelectBrand: React.FC<SelectBrandProps> = ({
  brands,
  setAdvertisers,
  setBrands,
  dataTestId,
  placeholder,
  theme,
  size,
  padding,
  shadow,
  shape,
  withLabel = false,
}) => {
  const advertiser = useSelector((state: Store) => state.dealManagement.commonDeal.advertiser);
  const brand = useSelector((state: Store) => state.dealManagement.commonDeal.brand);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const sourceSystem = useSelector((state: Store) => state.dealManagement.commonDeal.sourceSystem);
  const exclusions = useSelector((state: Store) => state.dealManagement.commonDeal.exclusions);

  const dispatch = useDispatch();
  const { onLoadBrands } = useLoadLookup(0);
  const readOnly = useDealFormReadOnly();
  const { isDirectSalesCampaignType } = useCampaignType();
  const previousAdvertiser = usePrevious(advertiser);
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;

  const onBrandChange = (selectedBrand: SelectedBrand | null): void => {
    if (!selectedBrand) {
      dispatch(changeDealInfo({ advertiser: null, productCategory: null }));
      setAdvertisers([]);
      setBrands([]);
    } else {
      if (selectedBrand.advertisers) {
        dispatch(changeDealInfo({ advertiser: selectedBrand.advertisers[0] }));
        setAdvertisers(selectedBrand.advertisers);

        if (isDirectSalesCampaignType) {
          const { organisations } = exclusions;
          dispatch(
            changeDealInfo({
              exclusions: {
                ...exclusions,
                organisations: updateOrganisations(
                  organisations,
                  selectedBrand.advertisers[0],
                  previousAdvertiser || null,
                ),
              },
            }),
          );
        }
      }

      dispatch(changeDealInfo({ productCategory: selectedBrand.productCategory }));
    }
  };

  return (
    <>
      {withLabel ? (
        <Label
          id="brand"
          theme={InputTheme.FLAT_GRAY}
          label="Brand"
          isRequired={sourceSystem !== SOURCE_SYSTEM.DV360}
        />
      ) : null}
      <AsyncSelect
        dataTestId={dataTestId}
        name="brand"
        placeholder={placeholder}
        selectedValue={brand}
        loadData={(_: string, query: string): Promise<BrandWithProductCategory[]> => {
          if (brands.length) {
            return Promise.resolve(brands.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase())));
          }

          return onLoadBrands(_, query);
        }}
        onSelect={(dropdownName: string, selectedBrand: SelectedBrand | null) => {
          dispatch(changeDealInfo({ [dropdownName]: selectedBrand }));
          onBrandChange(selectedBrand);
        }}
        defaultOptions={brands}
        isDisabled={readOnly && !isPendingReservation}
        theme={theme}
        size={size}
        padding={padding}
        shadow={shadow}
        shape={shape}
      />
    </>
  );
};
