import { cloneDeep, isEmpty } from 'lodash';
import {
  DirectSalesIntendedDealLineState,
  DirectSalesState,
  DirectSalesStatusLabels,
  DirectSalesStatusOption,
} from 'components/common/types/DirectSalesCampaign.types';

export const getStatusOptionLabel = (option: DirectSalesStatusOption): string => {
  return option in DirectSalesStatusLabels ? DirectSalesStatusLabels[option] : '';
};

export const generateNewTransientStates = (
  transientLineStates: DirectSalesIntendedDealLineState,
  currentLineId: string,
  status: DirectSalesStatusOption,
  currentLineExpiry: { expires?: string | Date },
): DirectSalesIntendedDealLineState => {
  const currentLineStatus = isEmpty(currentLineExpiry) ? { event: status } : { event: status, ...currentLineExpiry };

  return {
    ...transientLineStates,
    [currentLineId]: currentLineStatus,
  };
};

export const unifyTransientLinesStates = (
  transientLineStates: DirectSalesIntendedDealLineState,
  status: DirectSalesStatusOption,
  currentLineExpiry: { expires?: string | Date },
): DirectSalesIntendedDealLineState => {
  const currentCampaignStatus = isEmpty(currentLineExpiry)
    ? { event: status }
    : { event: status, ...currentLineExpiry };

  const newTransientLineStates = cloneDeep(transientLineStates);

  Object.keys(transientLineStates).forEach((key) => {
    newTransientLineStates[key] = currentCampaignStatus;
  });

  return newTransientLineStates;
};

export const transformTransientLinesStatesIntoLinesStates = (
  lineStates: DirectSalesIntendedDealLineState,
): Record<string, DirectSalesState> => {
  const newLineStates: Record<string, DirectSalesState> = {};

  Object.entries(lineStates).forEach(([key, { event, expires }]) => {
    newLineStates[key] = { status: event, expires };
  });

  return newLineStates;
};
