import { FC } from 'react';
import { useSelector } from 'react-redux';

import Button, { Color, Size } from 'lib/Button';

import Tooltip, { TooltipDirection, TooltipSize, TooltipTheme } from 'components/patterns/Tooltip';

import { FrontEndType } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';

import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';

import { getActionConditions } from 'components/pages/DealWithLines/common/getActionConditions';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';

import { AddNewLineProps } from './AddNewLine.types';

const AddNewLine: FC<AddNewLineProps> = ({
  addNewLineLabel = 'New deal line',
  isAddNewLineDisabled,
  onNewLineClick,
}) => {
  const dealConfig = useSelector((state: Store) => state.dealConfig);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const dealManagementState = useSelector((state: Store) => state.dealManagement);
  const frontEndType = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frontEndType);
  const isAdServerMarket = useSelector(getIsAdServer);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const isReseller = useSelector(getIsReseller);

  const { isDirectSalesCampaignType } = useCampaignType();
  const { createLine } = getActionConditions(dealManagementState, isAdServerMarket, dealConfig, isReseller);

  const noDirectSalesCampaign = isDirectSalesCampaignType && !dealId;

  // TODO: Remove direct sales check once the logic for adding lines is ready (ADS-6894)
  const isAddNewLineButtonDisabled =
    frontEndType === FrontEndType.PLANNER
      ? isAddNewLineDisabled || isEditingDisabled || !isDirectSalesCampaignType || noDirectSalesCampaign
      : createLine.disabled;

  return (
    <Tooltip
      tooltip={addNewLineLabel}
      direction={TooltipDirection.BOTTOM}
      delay
      contentSize={TooltipSize.MAX}
      theme={TooltipTheme.DARK}
    >
      <Button
        classNames="flex flex-row-reverse leading-tight border border-transparent"
        onClick={onNewLineClick}
        color={Color.TRANSPARENT}
        size={Size.SMALL}
        isDisabled={isAddNewLineButtonDisabled}
        label={`+ ${addNewLineLabel}`}
      />
    </Tooltip>
  );
};

export default AddNewLine;
