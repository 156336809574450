import {
  DirectSalesAllocationSolutionResponse,
  DirectSalesLineAllocation,
  DirectSalesStatusOption,
} from 'components/common/types/DirectSalesCampaign.types';
import { isDirectSalesLinePriced } from './getIsDirectSalesLinePriced';

const transformDirectSalesLineAllocation = (
  allocationResponse: DirectSalesAllocationSolutionResponse | null,
  lineStatus: DirectSalesStatusOption,
  lineId?: string,
): DirectSalesLineAllocation => {
  if (!allocationResponse || !lineId || !isDirectSalesLinePriced(lineStatus)) return {};

  const campaignAllocation = allocationResponse?.measures?.campaignMeasures?.[0];
  const lineAllocationResponse = campaignAllocation?.bookingMeasures?.find((measure) => measure.bookingId === lineId);

  if (!lineAllocationResponse) return {};

  const lineAllocation: DirectSalesLineAllocation = {};

  lineAllocationResponse.objectiveMeasures.forEach(({ value: allocated, status, objectiveId }) => {
    lineAllocation[+objectiveId] = { allocated, status };
  });

  return lineAllocation;
};

export default transformDirectSalesLineAllocation;
