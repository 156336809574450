import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import Input from 'lib/Input';
import { Label } from 'lib/Label';
import { CodeNameModel } from 'components/common/types';
import { Store } from 'components/common/types/Store.types';
import { InputType } from 'components/common/types/Input.types';
import { FormatOptions } from 'components/common/FormatOptions/FormatOptions';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import { useCategoryOptions } from 'components/pages/Planner/PlannerSecondaryPanel/useCategoryOptions';
import SelectionPermissionDropdown from '../SelectionPermissionDropdown/SelectionPermissionDropdown';
import { permissionOptions } from '../../consts';
import { isValueRequired } from '../../utils/isValueRequired';
import { isPanelConfirmDisabled } from '../../utils/isPanelConfirmDisabled';
import { ProductFormatsPanelProps } from './ProductFormatsPanelProps.types';

export const ProductFormatsPanel: React.FC<ProductFormatsPanelProps> = ({ onConfirm, onCancel }) => {
  const productFormats = useSelector((state: Store) => state.productManagement.newProduct?.formats);
  const defaultSelectedProductFormats =
    permissionOptions.find(({ code }) => code === productFormats?.selectionPermission) ?? permissionOptions[0];
  const [selectedPermission, setSelectedPermission] = useState<CodeNameModel>(defaultSelectedProductFormats);
  const [formats] = useState(productFormats?.value || []);
  const formatOptions = useSelector((state: Store) => state.userOptions.referenceOptions.format);

  const {
    onCategoryDirectionSort,
    searchText,
    openCategory,
    onFilterTextChange,
    selectedOptions,
    filteredOptions,
    getNumberOfFilteredOptions,
    sortDirection,
    includedOptions,
    excludedOptions,
    isOptionSelected,
    isOptionIncluded,
    onOptionIncludeExcludeChange,
    onToggleCategory,
    setSelectedOptions,
  } = useCategoryOptions(formatOptions as unknown as Record<string, CodeNameModel[]>, formats);

  const isChange = useMemo(() => !isEqual(selectedOptions, formats), [selectedOptions, formats]);

  return (
    <PlannerSecondaryPanel
      title="Formats"
      isDisabled={isPanelConfirmDisabled({
        field: productFormats,
        selectedPermission,
        newFieldValue: selectedOptions.length ? selectedOptions : undefined,
      })}
      onSubmit={() => onConfirm({ selectedPermission, formats: selectedOptions })}
      onCancel={onCancel}
      isChange={isChange}
      customStyles={{
        left: 'auto',
        right: '22px',
        width: '438px',
        height: 'unset',
        maxHeight: 'unset',
        bottom: '82px',
      }}
    >
      <SelectionPermissionDropdown
        selectedPermission={selectedPermission}
        setSelectedPermission={setSelectedPermission}
      />

      {isValueRequired(selectedPermission.code) && (
        <>
          <div className="my-4">
            <SectionDivider />
          </div>

          <Label label="Select formats" />

          <div className="mb-2">
            <Input
              type={InputType.SEARCH}
              value={searchText}
              placeholder="Search formats"
              onChange={onFilterTextChange}
            />
          </div>

          <FormatOptions
            filteredOptions={filteredOptions}
            selectedFormats={selectedOptions}
            onCategoryDirectionSort={onCategoryDirectionSort}
            sortDirection={sortDirection}
            onToggleCategory={onToggleCategory}
            setSelectedOptions={setSelectedOptions}
            getNumberOfFilteredOptions={getNumberOfFilteredOptions}
            isOptionIncluded={isOptionIncluded}
            isOptionSelected={isOptionSelected}
            onOptionIncludeExcludeChange={onOptionIncludeExcludeChange}
            openCategory={openCategory}
            includedOptions={includedOptions}
            excludedOptions={excludedOptions}
          />
        </>
      )}
    </PlannerSecondaryPanel>
  );
};
