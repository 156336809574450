import { DirectSalesState, DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';
import { useSelector } from 'react-redux';
import { getEveryLineState } from 'store/dealManagement/selectors';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Store } from 'components/common/types/Store.types';
import { isDirectSalesCampaignCloned } from 'components/pages/Planner/hooks/utils/isDirectSalesCampaignCloned';
import { transformTransientLinesStatesIntoLinesStates } from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/Status/StatusPanel/utils';

interface UseDirectSalesCampaignStatus {
  campaignState: DirectSalesState;
  campaignStateOptions: DirectSalesStatusOption[];
  isDisabled: boolean;
}

const useDirectSalesCampaignStatus = (): UseDirectSalesCampaignStatus => {
  const linesStates = useSelector(getEveryLineState);
  const transientLineStates = useSelector((state: Store) => state.dealManagement.directSales.transientLineStates);
  const campaignId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const allowedStateTransitions = useSelector((state: Store) => state.dealManagement.meta.allowedStateTransitions);

  const getCampaignState = (data: DirectSalesState[]): DirectSalesState => {
    if (!data.length) return { status: DirectSalesStatusOption.DRAFT_UPDATING };

    const activeStates = data.filter((state) => state.status !== DirectSalesStatusOption.CANCELLED);
    const isEveryStateCancelled = !activeStates.length;

    if (isEveryStateCancelled) return { status: DirectSalesStatusOption.CANCELLED };

    const [singleActiveState] = activeStates;
    const isEveryStateOption = activeStates.every((state) => state.status === DirectSalesStatusOption.OPTION);

    if (isEveryStateOption) {
      const isEveryOptionWithDropDate = activeStates.every(
        (state) => state.expires instanceof Date && !Number.isNaN(state.expires.getTime()),
      );

      if (!isEveryOptionWithDropDate) return { status: DirectSalesStatusOption.OPTION };

      const isEveryLineDropDateEqual = activeStates.every(
        (state) => (state.expires as Date).getTime() === (singleActiveState.expires as Date).getTime(),
      );

      if (isEveryLineDropDateEqual) return singleActiveState;

      return { status: DirectSalesStatusOption.OPTION };
    }

    const isEveryStateEqual = activeStates.every((state) => state.status === singleActiveState.status);

    if (isEveryStateEqual) return singleActiveState;

    return { status: DirectSalesStatusOption.MIXED };
  };

  const directSalesCampaignState = useMemo((): DirectSalesState => {
    if (isDirectSalesCampaignCloned(campaignId) && transientLineStates) {
      const transformedTransientLineStates = transformTransientLinesStatesIntoLinesStates(transientLineStates);
      const transientStatuses = Object.values(transformedTransientLineStates).map((state) => state);

      return getCampaignState(transientStatuses);
    }

    return getCampaignState(linesStates);
  }, [campaignId, linesStates, transientLineStates]);

  const campaignStateOptions = allowedStateTransitions[directSalesCampaignState.status];

  return {
    campaignState: directSalesCampaignState,
    campaignStateOptions,
    isDisabled: isEmpty(campaignStateOptions),
  };
};

export default useDirectSalesCampaignStatus;
