import { DirectSalesState } from 'components/common/types/DirectSalesCampaign.types';
import { DirectSalesCampaignDealLineStateResponse } from 'components/common/types/DirectSalesCampaignResponse.types';

export const transformCampaignStatusResponse = (
  data: DirectSalesCampaignDealLineStateResponse,
): Record<string, DirectSalesState> => {
  return Object.entries(data.states).reduce(
    (acc, [lineId, { state: savedState }]) => {
      acc[lineId] = {
        status: savedState.status,
        expires: savedState.expires ? new Date(savedState.expires) : undefined,
      };
      return acc;
    },
    {} as Record<string, DirectSalesState>,
  );
};
