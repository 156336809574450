import { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import classNames from 'classnames/bind';
import Chip from 'lib/Chip';
import { Color, Size } from 'lib/Chip/Chip.types';
import { CodeNameModel } from 'components/common/types';
import { searchProductNameOrId } from '../utils/searchProductNameOrId';
import { getFolderNames } from '../utils/getFolderNames';
import { getProductsToDisplayForDisabledToggle } from '../utils/getProductsToDisplayForDisabledToggle';
import { defaultAllFoldersProductOption, getProductsByFolderName } from '../utils/getProductsByFolderName';
import { ProductCreationInterface, ProductCreationStatus } from './ProductsTable.types';
import styles from './styles.pcss';
import ProductOptionsMenu from '../ProductOptionsMenu';
import ProductsTableFilters from './ProductsTableFilters';

const cx = classNames.bind(styles);

const mockProductCreationData = [
  {
    id: '1234',
    productName: 'First Test Product',
    folder: 'Folder 1',
    status: ProductCreationStatus.ACTIVE,
  },
  {
    id: '5678',
    productName: 'Second Test Product',
    folder: 'Folder 1',
    status: ProductCreationStatus.DISABLED,
  },
  {
    id: '9101',
    productName: 'Third Test Product',
    folder: 'Folder 2',
    status: ProductCreationStatus.ACTIVE,
  },
];

const ProductsTable: React.FC = () => {
  const gridRef = useRef<AgGridReact<ProductCreationInterface>>(null);
  const [products] = useState<ProductCreationInterface[]>(mockProductCreationData);
  const [filteredProducts, setFilteredProducts] = useState<ProductCreationInterface[]>(products);
  const [isShowMoreMenuOpen, setIsShowMoreMenuOpen] = useState<string>('');
  const [selectedFolderGroup, setSelectedFolderGroup] = useState<string>(defaultAllFoldersProductOption.code);
  const [searchText, setSearchText] = useState<string>('');
  const [folders, setFolders] = useState<CodeNameModel[]>([]);

  useEffect(() => {
    const folderNames = getFolderNames(products);

    setFolders(folderNames);
  }, [products]);

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Folder',
        rowGroup: true,
        field: 'folder',
        flex: 1,
        hide: true,
      },
      {
        headerName: 'Name',
        field: 'productName',
        flex: 1,
      },
      { headerName: 'ID', field: 'id', enableRowGroup: true, flex: 1 },
      {
        headerName: 'Status',
        field: 'status',
        enableRowGroup: true,
        cellRenderer: (params: ICellRendererParams) => (
          <div className="flex justify-center">
            <Chip
              label={params.value}
              color={params.value === ProductCreationStatus.ACTIVE ? Color.PRIMARY : Color.GREY}
              size={Size.MEDIUM}
            />
          </div>
        ),
        flex: 1,
      },
      {
        headerName: '',
        field: 'id',
        enableRowGroup: true,
        cellRenderer: (params: ICellRendererParams) => {
          if (!params.node.group) {
            return (
              <div className="w-full flex justify-end">
                <ProductOptionsMenu
                  isOpen={isShowMoreMenuOpen === params.value}
                  onToggle={() => setIsShowMoreMenuOpen(params.value)}
                  onKeepAndDuplicate={() => {}}
                  onDisableAndDuplicate={() => {}}
                  onChangeOfStatus={() => {}}
                  status={params.data?.status}
                />
              </div>
            );
          }
          return null;
        },
        flex: 1,
      },
    ],
    [filteredProducts, isShowMoreMenuOpen],
  );

  const gridOptions: GridOptions<ProductCreationInterface> = {
    autoGroupColumnDef: {
      cellRendererParams: {
        suppressCount: true,
      },
    },
    columnDefs,
    suppressAsyncEvents: true,
    defaultColDef: {
      resizable: true,
      suppressMenu: true,
    },
    suppressAggFuncInHeader: true,
    suppressMovableColumns: true,
    onGridReady: (params) => {
      params.api.sizeColumnsToFit();
    },
  };

  const onFilterTextChange = (searchTerm: string): void => {
    if (searchTerm.length === 0) {
      setFilteredProducts(products);

      return;
    }

    if (searchTerm.length < 2) return;

    const foundProducts = searchProductNameOrId({ products, searchTerm });

    setFilteredProducts(foundProducts);
    setSearchText(searchTerm);
    setSelectedFolderGroup(defaultAllFoldersProductOption.code);
  };

  const onToggle = (isHideDisabled: boolean): void => {
    setFilteredProducts(getProductsToDisplayForDisabledToggle({ isHideDisabled, products }));
  };

  const onChangeSelectedFolderGroup = (folderName: string): void => {
    setSelectedFolderGroup(folderName);
    setFilteredProducts(getProductsByFolderName({ folderName, products }));
  };

  return (
    <div className={cx('ag-theme-material product-creation-manager-table h-full w-full bg-white')}>
      <div className="p-2 border-b">
        <ProductsTableFilters
          folders={folders}
          selectedFolderGroup={selectedFolderGroup}
          onChangeSelectedFolderGroup={onChangeSelectedFolderGroup}
          onFilterTextChange={onFilterTextChange}
          searchText={searchText}
          onToggle={onToggle}
        />
      </div>

      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={filteredProducts}
        defaultColDef={gridOptions.defaultColDef}
        autoGroupColumnDef={gridOptions.autoGroupColumnDef}
        pivotMode={false}
      />
    </div>
  );
};

export default ProductsTable;
