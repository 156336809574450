import FilterArea from 'components/common/FilterArea';
import { usePageFilters } from 'customHooks/usePageFilters';
import {
  FilterAdvertiser,
  FilterAgency,
  FilterBrand,
  FilterDateRange,
  FilterSpecialist,
  FilterStatus,
} from 'components/common/FilterArea/Filters';
import FilterProductCategoryLookup from 'components/common/FilterArea/Filters/FilterProductCategoryLookup';
import FilterTextInput from 'components/common/FilterArea/Filters/FilterTextInput/FilterTextInput';
import {
  DirectSalesFilteredCampaignsStatusTableResponse,
  DirectSalesStatusOption,
} from 'components/common/types/DirectSalesCampaign.types';
import { DirectSalesStatusLabel } from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/Status/StatusPanel/StatusPanel.types';
import { DirectSalesDealManagementFilters } from './Filters.types';
import FilterDate from './FilterDate';

const CAMPAIGN_STATUSES: { code: DirectSalesFilteredCampaignsStatusTableResponse; name: DirectSalesStatusLabel }[] = [
  { code: 'DRAFT', name: DirectSalesStatusLabel.DRAFT },
  { code: DirectSalesStatusOption.PROPOSAL, name: DirectSalesStatusLabel.PROPOSAL },
  { code: DirectSalesStatusOption.CONFIRMED, name: DirectSalesStatusLabel.CONFIRMED },
  { code: DirectSalesStatusOption.OPTION, name: DirectSalesStatusLabel.OPTION },
  { code: DirectSalesStatusOption.CANCELLED, name: DirectSalesStatusLabel.CANCELLED },
  { code: DirectSalesStatusOption.MIXED, name: DirectSalesStatusLabel.MIXED },
];

const Filters: React.FC = () => {
  const {
    changeFilters,
    filters: {
      startDate,
      endDate,
      advertiser,
      brand,
      productCategory,
      specialist,
      agency,
      updatedBy,
      createdBy,
      createdAt,
      dropDate,
      campaignName,
      campaignReference,
      campaignStatus,
    },
  } = usePageFilters<DirectSalesDealManagementFilters>('insights/direct-sales');

  return (
    <FilterArea
      advancedFilters={
        <div className="flex flex-row gap-[16px] [&>*]:flex-1">
          <FilterAgency changeFilters={changeFilters} agency={agency} />
          <FilterTextInput
            changeFilters={changeFilters}
            keyName="updatedBy"
            value={updatedBy}
            name="updated-by"
            placeholderText="Search updated by"
          />
          <FilterTextInput
            changeFilters={changeFilters}
            keyName="createdBy"
            value={createdBy}
            name="created-by"
            placeholderText="Search created by"
          />
          <FilterDate
            changeFilters={changeFilters}
            keyName="createdAt"
            selectedDate={createdAt}
            placeholder="Creation date"
          />
          <FilterDate
            changeFilters={changeFilters}
            keyName="dropDate"
            selectedDate={dropDate}
            placeholder="Drop date"
          />
        </div>
      }
      customWrapperStyles="w-full h-full flex flex-col gap-[16px]"
    >
      <div className="flex flex-row gap-[16px] [&>*]:flex-1">
        <FilterTextInput
          changeFilters={changeFilters}
          keyName="campaignName"
          value={campaignName}
          name="campaign-name"
          placeholderText="Campaign name"
        />
        <FilterTextInput
          changeFilters={changeFilters}
          keyName="campaignReference"
          value={campaignReference}
          name="campaign-reference"
          placeholderText="Campaign ref"
        />
        <FilterStatus
          status={campaignStatus}
          statusesList={CAMPAIGN_STATUSES}
          changeFilters={changeFilters}
          keyName="campaignStatus"
        />
        <FilterAdvertiser changeFilters={changeFilters} selectedAdvertiser={advertiser} />
        <FilterDateRange
          changeFilters={changeFilters}
          selectedStartDate={startDate}
          selectedEndDate={endDate}
          placeholderStartDate="From"
          placeholderEndDate="To"
        />
        <FilterBrand changeFilters={changeFilters} brand={brand} />
        <FilterProductCategoryLookup changeFilters={changeFilters} productCategory={productCategory} />
        <FilterSpecialist changeFilters={changeFilters} specialist={specialist} />
      </div>
    </FilterArea>
  );
};

export default Filters;
