import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Store } from 'components/common/types/Store.types';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';

export const useTransformedProductNetworks = (): NetworkCriteriaType[] => {
  const selectedNetworks = useSelector((state: Store) => state.productManagement.newProduct.networks?.value);
  const networks = useSelector((state: Store) => state.userOptions.networks);
  const [transformedNetworks, setTransformedNetworks] = useState<NetworkCriteriaType[]>([]);

  const updatedSelectedOptions = selectedNetworks?.map((selectedOption) => {
    const foundCategory = networks.find((category) =>
      category.networks.some((network) => network.code === selectedOption.code),
    );

    if (foundCategory) {
      const foundNetwork = foundCategory.networks.find((network) => network.code === selectedOption.code);

      if (foundNetwork) {
        return {
          ...selectedOption,
          name: foundNetwork.name,
          category: foundCategory.name,
          available: foundNetwork.assets,
        };
      }
    }

    return selectedOption;
  });

  useEffect(() => {
    setTransformedNetworks(updatedSelectedOptions ?? []);
  }, [selectedNetworks, networks]);

  return transformedNetworks;
};
