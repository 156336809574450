import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { clearItemInNewProduct } from 'store/productManagement/reducer';
import { ProductFieldName } from 'store/productManagement/reducer.types';
import networksSvg from 'assets/icons/networks.svg';
import SectionButton from 'lib/SectionButton';
import { SectionButtonTheme } from 'lib/SectionButton/SectionButton.types';
import { Store } from 'components/common/types/Store.types';
import { useOnChangePanelVisibility } from 'components/pages/PCM/hooks/useOnChangePanelVisibility';
import { sortNetworkCriteriaBySelectedValue } from 'utils/sortNetworkCriteriaBySelectedValue';
import { useTransformedProductNetworks } from './useTransformedProductNetworks';
import ProductNetworksPanel from './ProductNetworksPanel';
import { isValueRequired } from '../../utils/isValueRequired';
import { getNoRestrictionsDisplayValue } from '../../utils/getNoRestrictionsDisplayValue';

const fieldName = ProductFieldName.NETWORKS;

const ProductNetworks: React.FC = () => {
  const dispatch = useDispatch();
  const secondaryPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);
  const networkSelectedPermission = useSelector(
    (state: Store) => state.productManagement.newProduct.networks?.selectionPermission,
  );
  const onChangePanelVisibility = useOnChangePanelVisibility();
  const transformedNetworks = useTransformedProductNetworks();
  const isProductNetworksPanelOpen = secondaryPanelType === PlannerSecondaryPanelType.NETWORKS;

  const categorizedValues = useMemo(
    () => transformedNetworks && sortNetworkCriteriaBySelectedValue(transformedNetworks),
    [transformedNetworks],
  );

  return (
    <>
      <SectionButton
        dataTestId="section-button-networks"
        label="Networks"
        icon={networksSvg}
        onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.NETWORKS)}
        theme={networkSelectedPermission ? SectionButtonTheme.SOLID : SectionButtonTheme.DASHED}
        isActive={isProductNetworksPanelOpen}
        selected={
          isValueRequired(networkSelectedPermission)
            ? categorizedValues
            : getNoRestrictionsDisplayValue(networkSelectedPermission)
        }
        onClear={() => dispatch(clearItemInNewProduct({ fieldName }))}
      />

      {isProductNetworksPanelOpen && (
        <ProductNetworksPanel transformedNetworks={transformedNetworks} fieldName={fieldName} />
      )}
    </>
  );
};

export default ProductNetworks;
