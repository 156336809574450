import { AllocationReportData, MetricType } from 'components/common/AllocationReport/AllocationReport.types';
import { CancelFunctions } from 'components/common/types';
import createRequest from './request';
import { URLS } from './constants';
import { HTTP_METHODS } from '../../../config/config.common';

export const getProgrammaticSavedAllocationReport = async (
  campaignId: string,
  lineId: string,
  metricType: MetricType,
  cancelFunctions: CancelFunctions,
): Promise<AllocationReportData> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_FINAL_ALLOCATION_REPORT(campaignId, lineId, metricType),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_FINAL_ALLOCATION_REPORT) cancelFunctions.FETCH_FINAL_ALLOCATION_REPORT();
  cancelFunctions.FETCH_FINAL_ALLOCATION_REPORT = cancel;

  const result = await send();

  return result.data;
};

export const getProgrammaticProposalAllocationReport = async (
  proposalCampaignId: string,
  metricType: MetricType,
  cancelFunctions: CancelFunctions,
): Promise<AllocationReportData> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_PROPOSAL_ALLOCATION_REPORT(proposalCampaignId, metricType),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_PROPOSAL_ALLOCATION_REPORT) cancelFunctions.FETCH_PROPOSAL_ALLOCATION_REPORT();
  cancelFunctions.FETCH_PROPOSAL_ALLOCATION_REPORT = cancel;

  const result = await send();

  return result.data;
};

export const getDirectSalesAllocationReport = async (
  marketId: string,
  campaignId: string,
  dealLineId: string,
  metricType: MetricType,
  cancelFunctions: CancelFunctions,
): Promise<AllocationReportData> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_DIRECT_SALES_ALLOCATION_REPORT(marketId, campaignId, dealLineId, metricType),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_DIRECT_SALES_ALLOCATION_REPORT) cancelFunctions.FETCH_DIRECT_SALES_ALLOCATION_REPORT();
  cancelFunctions.FETCH_DIRECT_SALES_ALLOCATION_REPORT = cancel;

  const result = await send();

  return result.data;
};
