const merge = require('lodash/merge');
const config = require('./config.common');

module.exports = merge({}, config, {
  environment: 'dev',
  isPublisherActive: true,
  app: {
    protocol: 'https',
    host: 'prog.qa.viooh.io',
    port: null,
  },
  auth0: {
    domain: 'viooh-qa.eu.auth0.com',
    clientID: 'XLwfcb7cOHWfmtYytj2gYbiZNmpgPtyH',
    audience: 'https://auth0-qa-api.viooh.com/',
  },
  api: {
    BASE_URL: 'https://gateway-api.qa.viooh.io/',
  },
  planner: {
    URL: 'https://ds-allocation-genius.dev.viooh.io',
  },
  dsm: {
    BOOKING_URL: 'https://dsm-booking-service.qa.viooh.io/',
    ASSET_URL: 'https://dsm-asset-service.qa.viooh.io/',
    SEARCH_SERVICE_URL: 'https://dsm-search-service.qa.viooh.io/',
  },
  thoughtspot: {
    pacingBoardId: '9cb68021-8716-4c49-9335-6e61162ad48d',
  },
  features: (market) => {
    return {
      ...config.features(market),
      GOOGLEMAPS: true,
      OVERRIDE_DEAL_ID: true,
    };
  },
});
