import {
  DirectSalesLockStatusOption,
  DirectSalesStatusOption,
} from 'components/common/types/DirectSalesCampaign.types';
import { Store } from 'components/common/types/Store.types';
import { useSelector } from 'react-redux';
import { isReadOnly } from 'utils/isReadOnly';
import { getIsDirectSalesSessionLockedAndReadOnly } from 'store/dealManagement/selectors';
import { CampaignType } from './PlannerSections/types';

interface UseIsReadOnly {
  isLineReadOnly: boolean;
  isCampaignReadOnly: boolean;
}

const useIsReadOnly = (): UseIsReadOnly => {
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const status = useSelector((state: Store) => state.dealManagement.directSales?.state?.status);
  const campaignType = useSelector((state: Store) => state.dealManagement.campaignType);
  const lockStatus = useSelector((state: Store) => state.dealManagement.directSales?.lockState?.status);
  const { isDirectSalesSessionLockedAndReadOnly } = useSelector(getIsDirectSalesSessionLockedAndReadOnly);

  const isLineLocked = !!lockStatus && lockStatus === DirectSalesLockStatusOption.LOCKED;

  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled);

  if (campaignType !== CampaignType.DIRECT)
    return {
      isLineReadOnly: readOnly,
      isCampaignReadOnly: readOnly,
    };

  return {
    isLineReadOnly:
      isEditingDisabled ||
      status === DirectSalesStatusOption.CANCELLED ||
      isDirectSalesSessionLockedAndReadOnly ||
      isLineLocked,
    isCampaignReadOnly: isEditingDisabled || isDirectSalesSessionLockedAndReadOnly,
  };
};

export default useIsReadOnly;
