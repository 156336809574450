import * as React from 'react';
import { Suspense } from 'react';
import classNames from 'classnames/bind';
import * as Modal from 'react-modal';
import { Routes, Route, Navigate, NavigateFunction } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Auth from 'modules/Auth';
import getRoutes, { publicRoutes } from 'routes';

import { backgroundColorStyle, Banner, BannerType, borderColorStyle } from 'lib/Banner';
import withRouter from 'components/hocs/withRouter';
import withCancelRequest from 'components/hocs/withCancelRequest';
import PrivateRoute from 'components/common/Auth/Private';
import AuthCallback from 'components/common/Auth/Callback';
import SidebarMenu from 'components/common/SidebarMenu';
import Notifications from 'components/common/Notifications';
import { Preloader } from 'components/patterns/Loader';
import { URLS } from 'modules/api/constants';
import { getPublisherConfiguration, getPublisherFeatures } from 'modules/api/publisher';
import { CancelFunctions } from 'components/common/types';
import { mergePublisherAndEnvironmentFeatures } from 'utils/mergePublisherAndEnvironmentFeatures';
import { dataDogLogExternalPublisherId } from 'modules/DataDog';
import { withDsps } from 'components/hocs/withDsps';
import styles from './App.pcss';
import { PublisherConfiguration, PublisherConfigurationResponse, PublisherFeatures } from '../types/Publisher.types';

Modal.setAppElement('#root');

const cx = classNames.bind(styles);

type OwnPropsType = {
  cancelFunctions: CancelFunctions;
};

export type StateToPropsType = {
  bannerType: BannerType;
  environmentId: string;
  publisherConfiguration: PublisherConfiguration | {};
  publisherFeatures: PublisherFeatures | {};
};

export type DispatchToPropsType = {
  changeConfiguration: (configuration: PublisherConfigurationResponse) => void;
  changeFeatures: (features: PublisherFeatures) => void;
};

type HOCPropsType = {
  location: Location;
  navigate: NavigateFunction;
};

type PropsType = OwnPropsType & StateToPropsType & DispatchToPropsType & HOCPropsType;

class App extends React.Component<PropsType> {
  isGetMetaDataCalled = false;

  state = {
    isAuthenticated: false,
  };

  plannerUrl = URLS().PLANNER();

  postMessageToPlanner = (eventSource: MessageEventSource): void => {
    const { environmentId } = this.props;
    eventSource.postMessage({ type: 'env_response', id: environmentId }, this.plannerUrl);
  };

  async componentDidMount(): Promise<void> {
    const { environmentId, location, navigate } = this.props;

    window.addEventListener('message', (e) => {
      if (!e.source || e.origin !== this.plannerUrl || e.data !== 'env_request') return;
      this.postMessageToPlanner(e.source);
    });

    const isAuthenticated = await Auth.isAuthenticated();
    this.setState({
      isAuthenticated,
    });

    if (!isAuthenticated && !location.pathname.includes('accessdenied')) {
      await Auth.redirectToUniversalLoginPage();
    }

    if (!environmentId) {
      navigate('/landing');
    }

    if (environmentId && isAuthenticated) {
      this.getConfiguration();
    }
  }

  async componentDidUpdate(): Promise<void> {
    const isAuthenticated = await Auth.isAuthenticated();
    const { isAuthenticated: prevIsAuthenticated } = this.state;
    if (isAuthenticated !== prevIsAuthenticated) {
      this.setState({
        isAuthenticated,
      });
    }
    dataDogLogExternalPublisherId();
  }

  componentWillUnmount(): void {
    this.setState = () => {};
  }

  getConfiguration = async (): Promise<void> => {
    const { changeConfiguration, changeFeatures, cancelFunctions, environmentId, location } = this.props;
    const isAuthenticated = await Auth.isAuthenticated();

    try {
      if (isAuthenticated && environmentId && !location.pathname.includes('/landing')) {
        const configuration = await getPublisherConfiguration({ envId: environmentId, cancelFunctions });
        changeConfiguration(configuration);

        const publisherFeatures = await getPublisherFeatures({ envId: environmentId, cancelFunctions });
        const features = mergePublisherAndEnvironmentFeatures(environmentId, publisherFeatures);
        changeFeatures(features);
      }
    } catch {} // eslint-disable-line no-empty
  };

  render(): React.ReactNode {
    const { bannerType, environmentId, location, publisherConfiguration, publisherFeatures } = this.props;

    const isPublisherData = !isEmpty(publisherConfiguration) && !isEmpty(publisherFeatures);
    const isComponentReadyToRender = isPublisherData || location.pathname.includes('/landing');
    const { isAuthenticated } = this.state;

    const routes = isComponentReadyToRender ? getRoutes() : [];

    return (
      <div className={cx('body-base font-inter bg-primary-950')}>
        <SidebarMenu isRequiredMarketData={Boolean(isPublisherData && environmentId && isAuthenticated)} />

        <div
          className={cx(
            'border-6 rounded h-screen overflow-hidden ml-15 flex flex-col',
            bannerType === BannerType.NONE
              ? 'border-primary-950'
              : `${borderColorStyle[bannerType]} ${backgroundColorStyle[bannerType]}`,
          )}
        >
          <div className="flex flex-col h-full">
            <Banner />
            <div className="flex-grow rounded bg-neutral-100 overflow-hidden">
              <Suspense fallback={<Preloader />}>
                <Routes>
                  <Route path="/" element={<Navigate to="/landing" />} />
                  <Route path="/auth-callback" element={<AuthCallback />} />
                  {publicRoutes.map((route) => (
                    <Route key={route.id} path={route.path} element={<route.main />} />
                  ))}
                  {routes.map((route) => (
                    <Route
                      key={route.id}
                      path={route.path}
                      element={
                        <PrivateRoute
                          component={route.main as React.ComponentType}
                          pageAccessPermission={route.pageAccessPermission}
                        />
                      }
                    />
                  ))}
                </Routes>
              </Suspense>
            </div>
          </div>
        </div>
        <Notifications />
      </div>
    );
  }
}

export default withRouter(withCancelRequest(withDsps(App)));
