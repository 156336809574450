import { useCancelRequest } from 'customHooks/useCancelRequest';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { getReferenceData } from 'modules/api/lookups';
import { changeReferenceData } from 'store/userOptions/reducer';
import { ReferenceOptionsResponse } from 'store/userOptions/reducer.types';
import { useEffect } from 'react';
import { changeFormParams } from 'store/dealManagement/reducer';
import { DirectSalesMediaType } from 'components/common/types/DirectSalesCampaign.types';
import { AssetTypes } from 'components/common/types/AssetList.types';
import { transformFormat, transformLocation, transformDirectSalesTags } from '../PlannerSections/FiltersContent/utils';
import { UseReferenceDataProps } from './UseReferenceData.types';

const useReferenceData = ({ environments, mediaType, startDate, endDate }: UseReferenceDataProps): void => {
  const cancelFunctions = useCancelRequest();
  const dispatch = useDispatch();
  const market = useSelector((state: Store) => state.publisher.configuration.marketId);

  useEffect(() => {
    if (!environments.length || !mediaType || !startDate || !endDate) return;

    const loadReferenceData = async (): Promise<void> => {
      dispatch(changeFormParams({ isLoading: true }));

      try {
        const referenceData: ReferenceOptionsResponse = await getReferenceData(cancelFunctions, {
          market,
          channelCodes: environments.map((environment) => environment.code),
          assetTypes: [mediaType === DirectSalesMediaType.PAPER ? AssetTypes.STATIC : mediaType],
          startDate,
          endDate,
        });

        const { format, location, tagGroup } = referenceData;

        const { address, geographicalAreaLevel1, geographicalAreaLevel2, geographicalAreaLevel3, ...filteredLocation } =
          location;

        dispatch(
          changeReferenceData({
            tags: transformDirectSalesTags(tagGroup),
            format: transformFormat(format),
            location: transformLocation(filteredLocation),
          }),
        );
        dispatch(changeFormParams({ isLoading: false }));
      } catch {
        dispatch(changeFormParams({ isLoading: false }));
      }
    };

    loadReferenceData();
  }, [environments, mediaType, startDate, endDate]);
};

export default useReferenceData;
