import { CodeNameModel } from 'components/common/types';
import { Environment } from 'components/common/types/Deal.types';
import {
  DirectSalesCodeNameModel,
  DirectSalesMediaType,
  DirectSalesPricingMode,
} from 'components/common/types/DirectSalesCampaign.types';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';

export enum ProductFieldName {
  OBJECTIVES = 'objectives',
  NETWORKS = 'networks',
  PRICING_MODE = 'pricingMode',
  RANGE = 'range',
  FORMATS = 'formats',
  MEDIA_TYPE = 'mediaType',
}

export enum SelectionPermission {
  ALL_OPTIONS = 'ALL_OPTIONS',
  FIXED_VALUE = 'FIXED_VALUE',
  DEFAULT_VALUE = 'DEFAULT_VALUE',
  DISABLED = 'DISABLED',
  ALLOWED_VALUES = 'ALLOWED_VALUES',
}

export type SelectedProductTemplate = {
  id: string;
};

export type NewProductCreationPayload = {
  selectedPermission: CodeNameModel;
  fieldName: string;
  value?:
    | DirectSalesMediaType
    | DirectSalesPricingMode
    | Environment[]
    | DirectSalesCodeNameModel[]
    | ProductScheduleRange
    | NetworkCriteriaType[];
};

export type ClearNewProductCreationPayload = {
  fieldName: string;
};

export type ProductScheduleRange = {
  startDate?: Date;
  endDate?: Date;
};

export interface ProductManagement {
  newProduct: {
    id: string;
    name: string;
    folder: string;
    mediaType?: {
      selectionPermission: SelectionPermission;
      value?: DirectSalesMediaType;
    };
    pricingMode?: {
      selectionPermission: SelectionPermission;
      value?: DirectSalesPricingMode;
    };
    range?: {
      selectionPermission: SelectionPermission;
      value?: ProductScheduleRange;
    };
    channels?: {
      selectionPermission: SelectionPermission;
      value?: Environment[];
    };
    locations?: {
      selectionPermission: SelectionPermission;
      value?: DirectSalesCodeNameModel[];
    };
    formats?: {
      selectionPermission: SelectionPermission;
      value?: DirectSalesCodeNameModel[];
    };
    objectives?: {
      selectionPermission: SelectionPermission;
    };
    targets?: {
      budget: {
        selectionPermission: SelectionPermission;
        value?: number;
        priority?: DirectSalesCodeNameModel;
        greenTolerance?: number;
        orangeTolerance?: number;
      };
      impressions: {
        selectionPermission: SelectionPermission;
        value?: number;
        priority?: DirectSalesCodeNameModel;
        greenTolerance?: number;
        orangeTolerance?: number;
      };
      frames: {
        selectionPermission: SelectionPermission;
        value?: number;
        priority?: DirectSalesCodeNameModel;
        greenTolerance?: number;
        orangeTolerance?: number;
      };
    };
    networks?: {
      selectionPermission: SelectionPermission;
      value?: NetworkCriteriaType[];
    };
  };
  selectedProduct: SelectedProductTemplate;
  secondaryPanel: PlannerSecondaryPanelType;
}
