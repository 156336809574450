import { useNavigate } from 'react-router-dom';
import editSvg from 'assets/icons/edit.svg';
import copySvg from 'assets/icons/copy.svg';
import { DirectSalesFilteredCampaignsTableResponse } from 'components/common/types/DirectSalesCampaign.types';
import IconButton from 'lib/IconButton';
import { ButtonSize } from 'lib/IconButton/IconButton.types';

type ActionRendererProps = {
  data?: DirectSalesFilteredCampaignsTableResponse;
};

const ActionRenderer: React.FC<ActionRendererProps> = ({ data = {} }) => {
  const navigate = useNavigate();

  const { reference } = data;

  if (!reference) return null;

  const onEditButtonClick = (): void => {
    navigate(`/planner/direct-sales/${reference}`);
  };

  // Copy button function to be attached by the other DSM squad
  const onCopyButtonClick = (): void => {};

  return (
    <span className="table-action-button flex">
      <IconButton
        icon={editSvg}
        name="Edit campaign"
        dataTestId={`edit-${reference}`}
        handleOnClick={onEditButtonClick}
        buttonSize={ButtonSize.LARGE}
      />
      <IconButton
        icon={copySvg}
        name="Duplicate campaign"
        dataTestId={`duplicate-${reference}`}
        handleOnClick={onCopyButtonClick}
        buttonSize={ButtonSize.LARGE}
      />
    </span>
  );
};

export default ActionRenderer;
