import { useSelector } from 'react-redux';
import xlsx from 'json-as-xlsx';
import { isDirectSalesCampaignCloned } from 'components/pages/Planner/hooks/utils/isDirectSalesCampaignCloned';
import { AllocationReportData, MetricOption, ReportType, SelectedLine } from './AllocationReport.types';
import { Store } from '../types/Store.types';
import { generateFileName } from './utils';
import { HOURS } from './consts';

interface UseExportAllocationReport {
  exportProgrammaticAllocationReport: () => void;
  exportDirectSalesAllocationReport: () => void;
}

export const useExportAllocationReport = ({
  activeReportType,
  allocationReportData,
  selectedLine,
  selectedMetricType,
}: {
  activeReportType: ReportType;
  allocationReportData?: AllocationReportData;
  selectedLine: SelectedLine;
  selectedMetricType: MetricOption;
}): UseExportAllocationReport => {
  const campaignId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const { timezone } = useSelector((state: Store) => state.publisher.configuration);

  const isSavedReport = activeReportType === ReportType.SAVED;

  const generateReport = (fileName: string, sheetName: string, withRowGroupColumns: boolean): void => {
    if (!allocationReportData) return;

    const isHourLevelData = !!allocationReportData.frames[0].allocations[0].days[0].hours?.length;

    const columns = [
      {
        label: 'Frame ID',
        value: 'frameId',
      },
      ...(withRowGroupColumns
        ? [
            {
              label: 'City',
              value: 'city',
            },
            {
              label: 'Product format',
              value: 'productFormat',
            },
          ]
        : []),
      {
        label: 'Week number (Local Time)',
        value: 'weekNumber',
      },
      {
        label: 'Week period (Local Time)',
        value: 'weekPeriod',
      },
      {
        label: 'Day (Local Time)',
        value: 'date',
      },
      ...(isHourLevelData
        ? [
            {
              label: 'Hour (Local Time)',
              value: 'hour',
            },
          ]
        : []),
      {
        label: 'Datetime (Local Time)',
        value: 'dateTime',
      },
      {
        label: selectedMetricType.name,
        value: selectedMetricType.code,
      },
    ];

    const hourLevelContent = allocationReportData.frames.flatMap((frame) =>
      frame.allocations.flatMap((allocation) =>
        allocation.days.flatMap((date) =>
          HOURS.map(({ id }) => {
            const formattedAllocationHour = id < 10 ? `0${id}` : id;
            const dateTime = `${date.date} ${formattedAllocationHour}:00:00.000 ${timezone}`;
            const weekPeriod = `${allocation.days[0].date} - ${allocation.days.at(-1)!.date}`;

            return {
              frameId: frame.frameId,
              city: frame.town?.name,
              productFormat: frame.productFormat?.name,
              weekNumber: allocation.allocationWeek,
              weekPeriod,
              date: date.date,
              hour: id,
              dateTime,
              [selectedMetricType.code]: date.hours?.[id] || '',
            };
          }),
        ),
      ),
    );

    const dayLevelContent = allocationReportData.frames.flatMap((frame) =>
      frame.allocations.flatMap((allocation) =>
        allocation.days.flatMap((date) => {
          const dateTime = `${date.date} 00:00:00.000 ${timezone}`;
          const weekPeriod = `${allocation.days[0].date} - ${allocation.days.at(-1)!.date}`;

          return {
            frameId: frame.frameId,
            city: frame.town?.name,
            productFormat: frame.productFormat?.name,
            weekNumber: allocation.allocationWeek,
            weekPeriod,
            date: date.date,
            dateTime,
            [selectedMetricType.code]: date.total || '',
          };
        }),
      ),
    );

    xlsx(
      [
        {
          sheet: sheetName,
          columns,
          content: isHourLevelData ? hourLevelContent : dayLevelContent,
        },
      ],
      { fileName },
    );
  };

  const exportDirectSalesAllocationReport = (): void => {
    const isCampaignCloned = isDirectSalesCampaignCloned(campaignId);
    const reportType = isCampaignCloned && isSavedReport ? 'Existing Saved Allocation' : 'Allocation';

    const fileName = generateFileName({
      activeReportType: reportType,
      selectedMetricType,
      selectedLine,
      timezone,
    });

    generateReport(fileName, reportType, false);
  };

  const exportProgrammaticAllocationReport = (): void => {
    const fileName = generateFileName({
      activeReportType: isSavedReport ? 'Alloc' : 'Prop',
      selectedMetricType,
      selectedLine,
      timezone,
    });
    const sheetName = isSavedReport ? 'Allocation Report' : 'Proposal Report';

    generateReport(fileName, sheetName, true);
  };

  return {
    exportDirectSalesAllocationReport,
    exportProgrammaticAllocationReport,
  };
};
