import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Store } from 'components/common/types/Store.types';
import { DealStatus } from 'components/common/types/Deal.types';
import { isDirectSalesCampaignCloned } from 'components/pages/Planner/hooks/utils/isDirectSalesCampaignCloned';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import CampaignId from './CampaignId';
import CampaignName from './CampaignName';
import CampaignBookingStatus from './CampaignBookingStatus';
import DirectSalesCampaignStatus from './DirectSalesCampaignStatus';

export interface OverviewProps {
  saveDeal: (bookingStatusCode: DealStatus | null, catchHandler?: (() => void) | undefined) => void;
}

const Overview: React.FC<OverviewProps> = ({ saveDeal }) => {
  const dealName = useSelector((state: Store) => state.dealManagement.commonDeal.dealName);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);

  const { isDirectSalesCampaignType } = useCampaignType();

  const { campaignId: originalCampaignId } = useParams();

  const campaignId = isDirectSalesCampaignCloned(dealId) ? originalCampaignId : dealId;

  return (
    <div className="grid grid-cols-1 pt-[3px] pr-[16px] pb-[8px] pl-[14px]" data-test-id="drawer-overview">
      <div className="pl-[2px]">
        <CampaignName campaignName={dealName} />
      </div>
      <div className="pl-[8px] pt-[2px]">
        <CampaignId campaignId={campaignId} />
      </div>
      <div className="pl-[8px] pt-[6px]">
        {isDirectSalesCampaignType ? <DirectSalesCampaignStatus /> : <CampaignBookingStatus onConfirm={saveDeal} />}
      </div>
    </div>
  );
};

export default Overview;
