import { FC } from 'react';

import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import ProductCategoryExclusivity from './ProductCategoryExclusivity';
import Juxtaposition from './Juxtaposition/Juxtaposition';
import BlockedExclusion from './BlockedExclusions';

import { ExclusionCriteriaPanelProps } from './ExclusionCriteriaPanel.types';
import Duplication from './Duplication/Duplication';

const ExclusionCriteriaPanel: FC<ExclusionCriteriaPanelProps> = ({
  advertiserBlockExclusion,
  agencyBlockExclusion,
  setAdvertiserExclusion,
  setAgencyExclusion,
  setSpecialistExclusion,
  specialistBlockExclusion,
  juxtapositionExclusion,
  duplicationExclusion,
  organisations,
  productCategories,
  productCategoryExclusivity,
  setJuxtapositionExclusion,
  setProductCategoryExclusivity,
  setDuplicationExclusion,
  onCancel,
  onConfirm,
  onRemoveOrganisation,
  onRemoveProductCategory,
}) => {
  return (
    <PlannerSecondaryPanel
      title="Exclusion Criteria"
      onSubmit={onConfirm}
      onCancel={onCancel}
      customStyles={{ left: '430px' }}
      isLineLevel={false}
    >
      <Duplication
        duplicationExclusion={duplicationExclusion}
        organisations={organisations}
        setDuplicationExclusion={setDuplicationExclusion}
        onRemove={onRemoveOrganisation}
      />
      <div className="border-b border-gray-200 py-3 mb-4">
        <Juxtaposition
          juxtapositionExclusion={juxtapositionExclusion}
          productCategories={productCategories}
          setJuxtapositionExclusion={setJuxtapositionExclusion}
          onRemove={onRemoveProductCategory}
        />
        <ProductCategoryExclusivity
          onSelect={setProductCategoryExclusivity}
          selectedValue={productCategoryExclusivity}
        />
      </div>
      <div className="flex flex-col border-b border-gray-200 mb-4 pb-1.5 space-y-1">
        <BlockedExclusion
          setAdvertiserExclusion={setAdvertiserExclusion}
          setAgencyExclusion={setAgencyExclusion}
          setSpecialistExclusion={setSpecialistExclusion}
          advertiserBlocklistExclusion={advertiserBlockExclusion}
          agencyBlocklistExclusion={agencyBlockExclusion}
          specialistBlocklistExclusion={specialistBlockExclusion}
        />
      </div>
    </PlannerSecondaryPanel>
  );
};

export default ExclusionCriteriaPanel;
