import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectOption from 'lib/SelectOption';
import { Label } from 'lib/Label';
import { mediaTypeDataOptions } from 'consts/mediaTypeDataOptions';
import { hideSecondaryPanel } from 'store/productManagement/reducer';
import { Store } from 'components/common/types/Store.types';
import { DirectSalesMediaTypeLabel } from 'components/common/types/DirectSalesCampaign.types';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import { isValueRequired } from 'components/pages/PCM/Products/utils/isValueRequired';
import { CodeNameModel } from 'components/common/types';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import SelectionPermissionDropdown from '../SelectionPermissionDropdown/SelectionPermissionDropdown';
import { requiredFieldPermissionOptions } from '../../consts';
import { isPanelConfirmDisabled } from '../../utils/isPanelConfirmDisabled';
import { ProductMediaTypePanelProps } from './ProductMediaType.types';

const ProductMediaTypePanel: React.FC<ProductMediaTypePanelProps> = ({ onConfirm }) => {
  const dispatch = useDispatch();
  const defaultMediaType = useSelector((state: Store) => state.productManagement.newProduct.mediaType);
  const defaultSelectedPermission =
    requiredFieldPermissionOptions.find(({ code }) => code === defaultMediaType?.selectionPermission) ??
    requiredFieldPermissionOptions[0];

  const [selectedPermission, setSelectedPermission] = useState<CodeNameModel>(defaultSelectedPermission);
  const [mediaType, setMediaType] = useState(defaultMediaType?.value);

  return (
    <PlannerSecondaryPanel
      title="Media type"
      onSubmit={() => onConfirm({ value: mediaType, selectedPermission })}
      onCancel={() => dispatch(hideSecondaryPanel())}
      isDisabled={isPanelConfirmDisabled({ field: defaultMediaType, selectedPermission, newFieldValue: mediaType })}
      customStyles={{
        left: 'auto',
        right: '22px',
        width: '438px',
        height: 'unset',
        maxHeight: 'unset',
        bottom: '82px',
      }}
    >
      <SelectionPermissionDropdown
        selectedPermission={selectedPermission}
        setSelectedPermission={setSelectedPermission}
        isReadOnly={false}
        permissionOptions={requiredFieldPermissionOptions}
      />
      {isValueRequired(selectedPermission.code) ? (
        <>
          <div className="my-4">
            <SectionDivider />
          </div>

          <Label label="Select media type" />

          <div className="flex flex-col space-y-1">
            {mediaTypeDataOptions.map((option) => (
              <SelectOption
                key={option.code}
                value={option}
                isSelected={mediaType === option.code}
                onClick={() => setMediaType(option.code)}
              >
                {DirectSalesMediaTypeLabel[option.code]}
              </SelectOption>
            ))}
          </div>
        </>
      ) : null}
    </PlannerSecondaryPanel>
  );
};

export default ProductMediaTypePanel;
