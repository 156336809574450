export interface ChipProps {
  label?: string;
  subLabel?: string;
  color?: Color;
  size?: Size;
  width?: Width;
  textPriority?: TextPriority;
  icon?: string;
  isRemovable?: boolean;
  onRemove?(label: string): void;
  isDisabled?: boolean;
  hasIndicator?: boolean;
  dataTestId?: string;
  subLabelClassName?: string;
}

export enum Color {
  PRIMARY = 'PRIMARY',
  GREY = 'GREY',
  PURPLE = 'PURPLE',
  PINK_RED = 'PINK_RED',
  YELLOW = 'YELLOW',
  LIME = 'LIME',
  PINK = 'PINK',
  ORANGE = 'ORANGE',
  SKY_BLUE = 'SKY_BLUE',
  RED = 'RED',
  GREEN = 'GREEN',
  DISABLED = 'DISABLED',
}

export enum Size {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum Width {
  MEDIUM = 'max-w-[200px]',
  SMALL = 'max-w-[160px]',
}

export enum TextPriority {
  LABEL = 'LABEL',
  EQUAL = 'EQUAL',
  SUBLABEL = 'SUBLABEL',
}

export const ChipTheme: Record<
  Color,
  {
    wrapperStyles: string;
    iconStyles: string;
    labelStyles: string;
    subLabelStyles: string;
    removeButtonStyles: string;
    indicatorStyles: string;
  }
> = {
  PRIMARY: {
    wrapperStyles: 'bg-primary-600-opacity-5 hover:bg-primary-600-opacity-10 active:bg-primary-600-opacity-20',
    iconStyles: 'text-primary-600',
    labelStyles: 'text-primary-600',
    subLabelStyles: 'bg-primary-700 text-neutral-50 group-hover:bg-primary-800 group-active:bg-primary-800',
    removeButtonStyles: 'text-primary-600',
    indicatorStyles: 'bg-primary-600',
  },
  GREY: {
    wrapperStyles: 'bg-neutral-950-opacity-5 hover:bg-neutral-950-opacity-10 active:bg-neutral-950-opacity-20',
    iconStyles: 'text-neutral-600 group-hover:text-neutral-800 group-active:text-neutral-800',
    labelStyles: 'text-neutral-600 group-hover:text-neutral-800 group-active:text-neutral-800',
    subLabelStyles: 'bg-neutral-800 text-neutral-50',
    removeButtonStyles: 'text-neutral-600 group-hover:text-neutral-800 group-active:text-neutral-800',
    indicatorStyles: 'bg-neutral-600',
  },
  PURPLE: {
    wrapperStyles: 'bg-purple-600-opacity-5 hover:bg-purple-600-opacity-10 active:bg-purple-600-opacity-20',
    iconStyles: 'text-purple-700',
    labelStyles: 'text-purple-700',
    subLabelStyles: 'bg-purple-700 text-neutral-50',
    removeButtonStyles: 'text-purple-700',
    indicatorStyles: 'bg-purple-700',
  },
  PINK_RED: {
    wrapperStyles: 'bg-pinkRed-600-opacity-5 hover:bg-pinkRed-600-opacity-10 active:bg-pinkRed-600-opacity-20',
    iconStyles: 'text-pinkRed-600',
    labelStyles: 'text-pinkRed-600',
    subLabelStyles: 'bg-pinkRed-700 text-neutral-50',
    removeButtonStyles: 'text-pinkRed-600',
    indicatorStyles: 'bg-pinkRed-600',
  },
  YELLOW: {
    wrapperStyles: 'bg-yellow-600-opacity-5 hover:bg-yellow-600-opacity-10 active:bg-yellow-600-opacity-20',
    iconStyles: 'text-yellow-600',
    labelStyles: 'text-yellow-600',
    subLabelStyles: 'bg-yellow-700 text-neutral-50',
    removeButtonStyles: 'text-yellow-600',
    indicatorStyles: 'bg-yellow-600',
  },
  LIME: {
    wrapperStyles: 'bg-lime-600-opacity-5 hover:bg-lime-600-opacity-10 active:bg-lime-600-opacity-20',
    iconStyles: 'text-lime-600',
    labelStyles: 'text-lime-600',
    subLabelStyles: 'bg-lime-700 text-neutral-50',
    removeButtonStyles: 'text-lime-600',
    indicatorStyles: 'bg-lime-600',
  },
  PINK: {
    wrapperStyles: 'bg-pink-600-opacity-5 hover:bg-pink-600-opacity-10 active:bg-pink-600-opacity-20',
    iconStyles: 'text-pink-600',
    labelStyles: 'text-pink-600',
    subLabelStyles: 'bg-pink-700 text-neutral-50',
    removeButtonStyles: 'text-pink-600',
    indicatorStyles: 'bg-pink-600',
  },
  ORANGE: {
    wrapperStyles: 'bg-orange-600-opacity-5 hover:bg-orange-600-opacity-10 active:bg-orange-600-opacity-20',
    iconStyles: 'text-orange-600',
    labelStyles: 'text-orange-600',
    subLabelStyles: 'bg-orange-700 text-neutral-50',
    removeButtonStyles: 'text-orange-600',
    indicatorStyles: 'bg-orange-600',
  },
  SKY_BLUE: {
    wrapperStyles: 'bg-sky-500-opacity-5 hover:bg-sky-500-opacity-10 active:bg-sky-500-opacity-20',
    iconStyles: 'text-sky-500',
    labelStyles: 'text-sky-500',
    subLabelStyles: 'bg-sky-600 text-neutral-50',
    removeButtonStyles: 'text-sky-500',
    indicatorStyles: 'bg-sky-500',
  },
  RED: {
    wrapperStyles: 'bg-pinkRed-600-opacity-5 hover:bg-pinkRed-600-opacity-10 active:bg-pinkRed-600-opacity-20',
    iconStyles: 'text-pinkRed-700',
    labelStyles: 'text-pinkRed-700',
    subLabelStyles: 'bg-pinkRed-800 text-neutral-50',
    removeButtonStyles: 'text-pinkRed-700',
    indicatorStyles: 'bg-pinkRed-700',
  },
  GREEN: {
    wrapperStyles: 'bg-green-500-opacity-5 hover:bg-green-500-opacity-10 active:bg-green-500-opacity-20',
    iconStyles: 'text-green-700',
    labelStyles: 'text-green-700',
    subLabelStyles: 'bg-green-700 text-neutral-50',
    removeButtonStyles: 'text-green-700',
    indicatorStyles: 'bg-green-700',
  },
  DISABLED: {
    wrapperStyles: 'bg-neutral-950-opacity-5',
    iconStyles: 'text-neutral-400',
    labelStyles: 'text-neutral-400',
    subLabelStyles: 'bg-neutral-300 text-neutral-400',
    removeButtonStyles: 'text-neutral-400 cursor-not-allowed',
    indicatorStyles: 'bg-neutral-400',
  },
};

export const ChipSize: Record<
  Size,
  {
    wrapperSize: string;
    iconWithLabelSize: string;
    iconSize: string;
    labelSize: string;
    subLabelSize: string;
    removeButtonSize: string;
  }
> = {
  LARGE: {
    wrapperSize: 'rounded-md h-7',
    iconWithLabelSize: 'px-1.5',
    iconSize: 'w-4 h-4 stroke-1',
    labelSize: 'px-0.5 body-base',
    subLabelSize: 'px-2 rounded-md body-base',
    removeButtonSize: 'w-4 h-4 stroke-1',
  },
  SMALL: {
    wrapperSize: 'rounded-full h-5',
    iconWithLabelSize: 'px-[3px]',
    iconSize: 'w-3 h-3 stroke-1',
    labelSize: 'px-px body-sm',
    subLabelSize: 'px-[5px] rounded-full body-sm',
    removeButtonSize: 'w-3 h-3 stroke-1',
  },
  MEDIUM: {
    wrapperSize: 'rounded-full h-6',
    iconWithLabelSize: 'px-1.5',
    iconSize: 'w-4 h-4 stroke-1',
    labelSize: 'px-0.5 body-base',
    subLabelSize: 'px-2 rounded-full body-base',
    removeButtonSize: 'w-4 h-4 stroke-1',
  },
};
