import { CodeNameModel, FileList } from 'components/common/types';
import { Environment, RouteFrameCode } from 'components/common/types/Deal.types';
import {
  DirectSalesFilterItem,
  DirectSalesFilter,
  DirectSalesFilterTypes,
  DirectSalesNestedFilter,
  DirectSalesMediaType,
  DirectSalesUnitIncludedExcludedFilter,
  DirectSalesUnitListFilter,
  DirectSalesLogicFilterTypes,
  DirectSalesFilterGroupNames,
} from 'components/common/types/DirectSalesCampaign.types';

export const mappedFormatFilterName: Record<string, string> = {
  'Business Area': 'BusinessAreaFilter',
  'Size Code': 'FrameSizeFamilyFilter',
  'Product Format': 'ProductFormatFilter',
  'Revenue Stream': 'RevenueStreamFilter',
  'Marketing Name': 'ProductFormatMarketingFilter',
};

export const mapLocationFilter: Record<string, string> = {
  'Route Town': 'RouteTownFilter',
  Postcode: 'PostcodeFilter',
  'TV Area': 'TvAreaFilter',
  Conurbation: 'ConurbationFilter',
  Country: 'CountryFilter',
  'Geographical Area Level 1': 'GeographicalAreaLevelFilter',
  'Geographical Area Level 2': 'GeographicalAreaLevelFilter',
  'Geographical Area Level 3': 'GeographicalAreaLevelFilter',
  County: 'CountyFilter',
  Town: 'TownFilter',
  District: 'DistrictFilter',
};

export const mapTagFilter: Record<string, string> = {
  Tag: 'TagFilter',
};

export const reversedFilterNames = (mappedFilters: Record<string, string>): Record<string, string> => {
  const reversedFilters: Record<string, string> = {};

  Object.entries(mappedFilters).forEach(([key, value]) => {
    reversedFilters[value] = key;
  });

  return reversedFilters;
};

export const transformDealLineFilters = (
  dealLineFilters: CodeNameModel[],
  filterNames: Record<string, string>,
): DirectSalesFilter => {
  const includedFilters: DirectSalesFilterItem[] = [];
  const excludedFilters: DirectSalesFilterItem[] = [];

  dealLineFilters.forEach(({ category, code, name, include }) => {
    if (!category) return;

    const [filterType, ...rest] = Object.values(filterNames);

    const filter = {
      type: rest.length ? filterNames[category] : filterType,
      id: code,
      name,
    };

    if (include) {
      includedFilters.push(filter);
    } else {
      excludedFilters.push(filter);
    }
  });

  const notFilter = {
    type: DirectSalesFilterTypes.NotFilter,
    filter: {
      type: DirectSalesFilterTypes.AndFilter,
      filters: excludedFilters,
    },
  };

  return {
    type: DirectSalesFilterTypes.AndFilter,
    filters: [notFilter, ...includedFilters],
  };
};

export const transformChannelsFilters = (channels: Environment[]): DirectSalesFilterItem[] => {
  return channels.map((channel) => {
    return {
      type: 'ChannelFilter',
      id: channel.code,
      name: channel.name,
    };
  });
};

export const reverseTransformDealLineFilters = (
  directSalesResponseFilters: DirectSalesFilter,
  filterNames: Record<string, string>,
): CodeNameModel[] => {
  const filters: CodeNameModel[] = [];
  const reverseFilterTypeNames = reversedFilterNames(filterNames);

  const processFilter = (filter: DirectSalesLogicFilterTypes, include: boolean): void => {
    if ('id' in filter && 'name' in filter && reverseFilterTypeNames[filter.type]) {
      filters.push({
        category: reverseFilterTypeNames[filter.type],
        code: filter.id,
        name: filter.name,
        include,
      });
    }
  };

  const isNestedFilter = (filter: DirectSalesLogicFilterTypes): filter is DirectSalesNestedFilter => {
    return 'filter' in filter && 'filters' in filter.filter;
  };

  directSalesResponseFilters.filters.forEach((filter) => {
    if (isNestedFilter(filter)) {
      filter.filter.filters.forEach((nested) => processFilter(nested, false));
    } else if ('filters' in filter && Array.isArray(filter.filters)) {
      filter.filters.forEach((nested) => processFilter(nested, true));
    } else {
      processFilter(filter, true);
    }
  });

  return filters;
};

export const transformUploadedFilesFromResponse = (
  listFiles: FileList[],
  includedUnitFilters: DirectSalesUnitIncludedExcludedFilter[],
  excludedUnitFilters: DirectSalesUnitIncludedExcludedFilter[],
): void => {
  return listFiles.forEach(({ name: filename, include: fileWideInclude, fileContents }) => {
    if (!fileContents?.length) return;

    if (fileWideInclude !== undefined) {
      if (fileWideInclude === true) {
        fileContents.forEach(({ code }) => includedUnitFilters.push({ code, filename }));
        return;
      }

      if (fileWideInclude === false) {
        fileContents.forEach(({ code }) => excludedUnitFilters.push({ code, filename }));
        return;
      }
    }

    fileContents.forEach(({ code, include: individualFrameInclude }) => {
      if (!code) return;

      if (individualFrameInclude) {
        includedUnitFilters.push({ code, filename });
      } else {
        excludedUnitFilters.push({ code, filename });
      }
    });
  });
};

export const transformIndividuallySelectedFramesFromResponse = (
  frameCodes: RouteFrameCode[],
  includedUnitFilters: DirectSalesUnitIncludedExcludedFilter[],
  excludedUnitFilters: DirectSalesUnitIncludedExcludedFilter[],
): void => {
  return frameCodes.forEach(({ code, include }) => {
    if (!code) return;

    if (include) {
      includedUnitFilters.push({ code, filename: null });
    } else {
      excludedUnitFilters.push({ code, filename: null });
    }
  });
};

export const mergeExcludedFrames = (
  excludedUnitFilters: DirectSalesUnitIncludedExcludedFilter[],
): DirectSalesNestedFilter => {
  const uniqueExcludedFilesNames = [...new Set(excludedUnitFilters.map(({ filename }) => filename))];

  const excludedFrames = uniqueExcludedFilesNames.map((filename) => {
    return {
      type: DirectSalesFilterTypes.NotFilter,
      filter: {
        type: DirectSalesFilterTypes.AndFilter,
        filters: [
          {
            type: DirectSalesFilterGroupNames.UnitListFilter,
            filename,
            unitsIds: excludedUnitFilters
              .filter((individualFrames) => individualFrames.filename === filename)
              .map(({ code }) => code),
          },
        ],
      },
    };
  });

  return {
    type: DirectSalesFilterTypes.NotFilter,
    filter: {
      type: DirectSalesFilterTypes.AndFilter,
      filters: excludedFrames.flatMap((item) => item.filter.filters),
    },
  };
};

export const mergeIncludedFrames = (
  includedUnitFilters: DirectSalesUnitIncludedExcludedFilter[],
): DirectSalesUnitListFilter[] => {
  const uniqueIncludedFilesNames = [...new Set(includedUnitFilters.map(({ filename }) => filename))];

  return uniqueIncludedFilesNames.map((filename) => {
    return {
      type: DirectSalesFilterGroupNames.UnitListFilter,
      filename,
      unitsIds: includedUnitFilters
        .filter((individualFrames) => individualFrames.filename === filename)
        .map(({ code }) => code),
    };
  });
};

export const transformUnitListFilters = ({
  listFiles,
  frameCodes,
}: {
  listFiles?: FileList[];
  frameCodes?: RouteFrameCode[];
}): DirectSalesFilter | null => {
  if (!frameCodes?.length && !listFiles?.length) return null;

  const includedUnitFilters: DirectSalesUnitIncludedExcludedFilter[] = [];
  const excludedUnitFilters: DirectSalesUnitIncludedExcludedFilter[] = [];

  if (listFiles?.length) {
    transformUploadedFilesFromResponse(listFiles, includedUnitFilters, excludedUnitFilters);
  }

  if (frameCodes?.length) {
    transformIndividuallySelectedFramesFromResponse(frameCodes, includedUnitFilters, excludedUnitFilters);
  }

  const includedFrames = mergeIncludedFrames(includedUnitFilters);
  const excludedFrames = mergeExcludedFrames(excludedUnitFilters);

  return {
    type: DirectSalesFilterTypes.AndFilter,
    filters: excludedUnitFilters.length ? [...includedFrames, excludedFrames] : [...includedFrames],
  };
};

export const mergeFilters = (
  dealLineFormats: CodeNameModel[] = [],
  locations: CodeNameModel[] = [],
  tags: CodeNameModel[] = [],
  channels: Environment[],
  mediaType?: DirectSalesMediaType,
  listFiles?: FileList[],
  routeFrameCodes?: RouteFrameCode[],
): DirectSalesFilter => {
  const dealLineFilters = transformDealLineFilters(dealLineFormats, mappedFormatFilterName);
  const locationFilters = transformDealLineFilters(locations, mapLocationFilter);
  const tagsFilters = transformDealLineFilters(tags, mapTagFilter);
  const transformedChannelsFilter = transformChannelsFilters(channels);
  const unitListFilter = transformUnitListFilters({ listFiles, frameCodes: routeFrameCodes });
  const mediaTypeFilter = mediaType
    ? {
        type: 'MediaTypeFilter',
        mediaType,
      }
    : null;

  const dealLineExcludedFilters = dealLineFilters.filters.find(
    (filter) => filter.type === DirectSalesFilterTypes.NotFilter,
  ) as DirectSalesNestedFilter;
  const locationExcludedFilter = locationFilters.filters.find(
    (filter) => filter.type === DirectSalesFilterTypes.NotFilter,
  ) as DirectSalesNestedFilter;
  const tagsExcludedFilter = tagsFilters.filters.find(
    (filter) => filter.type === DirectSalesFilterTypes.NotFilter,
  ) as DirectSalesNestedFilter;
  const unitListExcludedFilter = unitListFilter
    ? (unitListFilter.filters.find(
        (filter) => filter.type === DirectSalesFilterTypes.NotFilter,
      ) as DirectSalesNestedFilter)
    : { filter: { filters: [] } };

  const dealLineIncludedFilters = dealLineFilters.filters.filter(
    (filter) => filter.type !== DirectSalesFilterTypes.NotFilter,
  );
  const locationIncludedFilters = locationFilters.filters.filter(
    (filter) => filter.type !== DirectSalesFilterTypes.NotFilter,
  );
  const tagsIncludedFilters = tagsFilters.filters.filter((filter) => filter.type !== DirectSalesFilterTypes.NotFilter);
  const unitListIncludedFilter = unitListFilter
    ? unitListFilter.filters.filter((filter) => filter.type !== DirectSalesFilterTypes.NotFilter)
    : [];

  const combinedExcludedFilters = [
    ...(dealLineExcludedFilters?.filter?.filters || []),
    ...(locationExcludedFilter?.filter?.filters || []),
    ...(tagsExcludedFilter?.filter?.filters || []),
    ...(unitListExcludedFilter?.filter?.filters || []),
  ];

  const mergedExcludedFilter =
    combinedExcludedFilters.length > 0
      ? {
          type: DirectSalesFilterTypes.NotFilter,
          filter: {
            type: DirectSalesFilterTypes.OrFilter,
            filters: combinedExcludedFilters,
          },
        }
      : null;

  const channelsFilter =
    transformedChannelsFilter.length > 0
      ? {
          type: DirectSalesFilterTypes.OrFilter,
          filters: transformedChannelsFilter,
        }
      : null;

  const combinedIncludedFilters = [
    {
      type: DirectSalesFilterTypes.OrFilter,
      filters: [...(dealLineIncludedFilters || [])],
    },
    {
      type: DirectSalesFilterTypes.OrFilter,
      filters: [...(locationIncludedFilters || [])],
    },
    {
      type: DirectSalesFilterTypes.OrFilter,
      filters: [...(tagsIncludedFilters || [])],
    },
    {
      type: DirectSalesFilterTypes.OrFilter,
      filters: [...(unitListIncludedFilter || [])],
    },
  ].filter((filter) => filter.filters.length > 0);

  const mergeAllFilters = [
    mediaTypeFilter,
    channelsFilter,
    ...combinedIncludedFilters,
    ...(mergedExcludedFilter ? [mergedExcludedFilter] : []),
  ].filter((filter): filter is DirectSalesFilter => filter !== null);

  return {
    type: DirectSalesFilterTypes.AndFilter,
    filters: mergeAllFilters,
  };
};
