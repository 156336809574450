import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideBanner, showBanner } from 'store/banner/reducer';
import { BannerType } from 'lib/Banner';
import useNetworkReferenceData from 'components/pages/Planner/hooks/useNetworkReferenceData';
import useReferenceData from 'components/pages/Planner/hooks/useReferenceData';
import { hideSecondaryPanel, clearNewProduct } from 'store/productManagement/reducer';
import { getDefaultMandatoryData } from 'components/pages/PCM/Products/utils/getDefaultMandatoryData';
import { CreateProductContent } from './CreateProductContent/CreateProductContent';
import { CreateProductSidebar } from './CreateProductSidebar.tsx/CreateProductSidebar';

const mandatoryData = getDefaultMandatoryData();

const CreateProduct: React.FC = () => {
  const dispatch = useDispatch();

  useReferenceData(mandatoryData);
  useNetworkReferenceData(mandatoryData);

  useEffect(() => {
    dispatch(
      showBanner({
        type: BannerType.INFO,
        headerInfo: 'Product Creation manager',
        confirmButton: {
          actionCallback: () => undefined,
          buttonLabel: 'Save',
        },
      }),
    );

    return () => {
      dispatch(hideBanner());
      dispatch(hideSecondaryPanel());
      dispatch(clearNewProduct());
    };
  }, []);

  return (
    <div className="flex relative flex-1 overflow-y-auto overflow-x-hidden h-screen">
      <CreateProductSidebar />
      <div className="w-full grid grid-cols-[3fr_2fr]">
        <CreateProductContent />
      </div>
    </div>
  );
};

export default CreateProduct;
