import { SelectionPermission } from 'store/productManagement/reducer.types';

export const permissionOptions = [
  { code: SelectionPermission.DISABLED, name: 'Not in product' },
  { code: SelectionPermission.ALL_OPTIONS, name: 'In product - no restrictions' },
  { code: SelectionPermission.FIXED_VALUE, name: 'In product - defined value' },
];

export const requiredFieldPermissionOptions = [
  { code: SelectionPermission.ALL_OPTIONS, name: 'In product - no restrictions' },
  { code: SelectionPermission.FIXED_VALUE, name: 'In product - defined value' },
];

export const rangeSpecificPermissionOptions = [
  { code: SelectionPermission.ALL_OPTIONS, name: 'In product - no restrictions' },
  { code: SelectionPermission.ALLOWED_VALUES, name: 'In product - defined validity range' },
];
