export enum Color {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  TRANSPARENT = 'TRANSPARENT',
  GREY = 'GREY',
  PINK = 'PINK',
  ORANGE = 'ORANGE',
  DANGER = 'DANGER',
  BLACK_AND_WHITE = 'BLACK_AND_WHITE',
}

export enum Size {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  FIT = 'FIT',
  WIDE = 'WIDE',
  FIT_CONTAINER = 'FIT_CONTAINER',
}

export enum TextSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export enum Align {
  START = 'START',
  CENTER = 'CENTER',
  END = 'END ',
}

export enum TextOverflow {
  HIDDEN = 'HIDDEN',
  VISIBLE = 'CENTER',
}

export const ButtonTheme: {
  theme: Record<Color, string>;
  size: Record<Size, string>;
  align: Record<Align, string>;
  textSize: Record<TextSize, string>;
  textOverflow: Record<TextOverflow, string>;
} = {
  theme: {
    [Color.PRIMARY]: 'bg-primary-900 text-primary-50 hover:bg-primary-700 active:bg-primary-600 shadow-sm',
    [Color.SECONDARY]:
      'bg-neutral-50 border border-neutral-300 text-primary-600 hover:bg-neutral-100 active:bg-neutral-200 disabled:border-none shadow-sm',
    [Color.TERTIARY]: 'bg-primary-100 text-primary-600 hover:bg-primary-200 active:bg-primary-300 disabled:border-none',
    [Color.TRANSPARENT]:
      'bg-transparent text-primary-600 hover:bg-primary-50 active:bg-primary-200 disabled:border-none',
    [Color.GREY]: 'bg-neutral-950-opacity-5 text-essential-secondary hover:bg-neutral-950-opacity-10',
    [Color.PINK]: 'bg-pink-650 text-neutral-50',
    [Color.DANGER]: 'bg-pinkRed-800 text-pinkRed-50 hover:bg-pinkRed-700 active:bg-pinkRed-900',
    [Color.BLACK_AND_WHITE]: 'bg-white border border-neutral-950-opacity-10 hover:bg-neutral-950-opacity-5 active',
    [Color.ORANGE]: 'bg-orange-50 text-orange-700 border border-orange-700',
  },
  size: {
    [Size.EXTRA_SMALL]: 'p-0.5',
    [Size.SMALL]: 'p-1',
    [Size.MEDIUM]: 'p-2',
    [Size.LARGE]: 'p-3',
    [Size.WIDE]: 'w-full',
    [Size.FIT]: 'w-fit',
    [Size.FIT_CONTAINER]: 'flex-1 p-3',
  },
  align: {
    [Align.START]: 'justify-start',
    [Align.CENTER]: 'justify-center',
    [Align.END]: 'justify-end',
  },
  textSize: {
    [TextSize.SMALL]: 'body-sm',
    [TextSize.MEDIUM]: 'sub-header-base',
  },
  textOverflow: {
    [TextOverflow.HIDDEN]: 'overflow-hidden',
    [TextOverflow.VISIBLE]: 'overflow-visible',
  },
};

export default interface ButtonProps {
  ariaLabel?: string;
  classNames?: string;
  color?: Color;
  dataTestId?: string;
  label?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  size?: Size;
  svg?: string;
  svgClassnames?: string;
  ddActionName?: string;
  textSize?: TextSize;
  textOverflow?: TextOverflow;
  align?: Align;
  dataPendoId?: string;
}
