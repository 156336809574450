import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePlannerSettings } from 'store/dealManagement/reducer';
import { getIsValidDirectSalesAvailabilityRequest } from 'store/dealManagement/selectors';
import { Store } from 'components/common/types/Store.types';

export const useDirectSummaryPanelButtonEnabled = (): boolean => {
  const dispatch = useDispatch();
  const assets = useSelector((store: Store) => store.dealManagement.commonDeal.currentLine.availability.assets);
  const isValidDirectSalesAvailabilityRequest = useSelector(getIsValidDirectSalesAvailabilityRequest);

  const isSummaryPanelButtonEnabled = isValidDirectSalesAvailabilityRequest && assets.length > 0;

  useEffect(() => {
    dispatch(updatePlannerSettings({ isDirectSummaryPanelOpen: isSummaryPanelButtonEnabled }));
  }, [isSummaryPanelButtonEnabled]);

  return isSummaryPanelButtonEnabled;
};
