import { CodeNameModel } from 'components/common/types';
import {
  DirectSalesCodeNameModel,
  DirectSalesMediaType,
  DirectSalesPricingMode,
} from 'components/common/types/DirectSalesCampaign.types';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';
import { isValueRequired } from 'components/pages/PCM/Products/utils/isValueRequired';

export type ConfirmPanelValue =
  | DirectSalesMediaType
  | NetworkCriteriaType[]
  | DirectSalesPricingMode
  | DirectSalesCodeNameModel[];

export const isPanelConfirmDisabled = ({
  field,
  selectedPermission,
  newFieldValue,
}: {
  field?: { value?: ConfirmPanelValue; selectionPermission: string };
  selectedPermission: CodeNameModel;
  newFieldValue?: ConfirmPanelValue;
}): boolean => {
  const permissionUnchanged = field?.selectionPermission === selectedPermission.code;
  const fieldValueUnchanged = field?.value === newFieldValue;

  if (isValueRequired(selectedPermission.code)) {
    if (permissionUnchanged && fieldValueUnchanged) {
      return true;
    }

    return !newFieldValue;
  }

  if (permissionUnchanged && fieldValueUnchanged) {
    return true;
  }

  if (permissionUnchanged && !fieldValueUnchanged) {
    return false;
  }

  return false;
};
