import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import plusSvg from 'assets/icons/plus.svg';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { ProductFieldName } from 'store/productManagement/reducer.types';
import { Store } from 'components/common/types/Store.types';
import { CodeNameModel } from 'components/common/types';
import { useOnChangePanelVisibility } from 'components/pages/PCM/hooks/useOnChangePanelVisibility';
import SectionButton from 'lib/SectionButton';
import { clearItemInNewProduct, changeNewProduct, hideSecondaryPanel } from 'store/productManagement/reducer';
import { SectionButtonTheme } from 'lib/SectionButton/SectionButton.types';
import { getNoRestrictionsDisplayValue } from '../../utils/getNoRestrictionsDisplayValue';
import ProductObjectivesPanel from './ProductObjectivesPanel';

const fieldName = ProductFieldName.OBJECTIVES;

export const ProductObjectives: React.FC = () => {
  const dispatch = useDispatch();
  const onChangePanelVisibility = useOnChangePanelVisibility();
  const secondaryPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);
  const defaultSelectedPermission = useSelector(
    (state: Store) => state.productManagement.newProduct.objectives?.selectionPermission,
  );

  const isProductObjectivesOpen = secondaryPanelType === PlannerSecondaryPanelType.OBJECTIVE;

  const handleSubmit = ({ selectedPermission }: { selectedPermission: CodeNameModel }): void => {
    dispatch(changeNewProduct({ fieldName, value: undefined, selectedPermission }));
    dispatch(hideSecondaryPanel());
  };

  return (
    <>
      <SectionButton
        dataTestId="section-button-objective-permission"
        label="Objective"
        onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.OBJECTIVE)}
        theme={defaultSelectedPermission ? SectionButtonTheme.SOLID : SectionButtonTheme.DASHED}
        icon={plusSvg}
        selected={getNoRestrictionsDisplayValue(defaultSelectedPermission)}
        onClear={() => dispatch(clearItemInNewProduct({ fieldName }))}
      />
      {isProductObjectivesOpen && <ProductObjectivesPanel onConfirm={handleSubmit} />}
    </>
  );
};
