import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { isValueRequired } from 'components/pages/PCM/Products/utils/isValueRequired';
import {
  NewProductCreationPayload,
  ProductManagement,
  SelectedProductTemplate,
  ClearNewProductCreationPayload,
} from './reducer.types';

const initialState: ProductManagement = {
  newProduct: {
    id: '',
    name: '',
    folder: '',
    mediaType: undefined,
    pricingMode: undefined,
    range: undefined,
    channels: undefined,
    locations: undefined,
    formats: undefined,
    targets: undefined,
  },
  selectedProduct: {
    id: '',
  },
  secondaryPanel: PlannerSecondaryPanelType.NONE,
};

export const productManagementSlice = createSlice({
  name: 'productManagement',
  initialState,
  reducers: {
    changeSelectedProduct: (state, action: PayloadAction<SelectedProductTemplate>) => {
      state.selectedProduct = action.payload;
    },
    changeNewProductName: (state, action: PayloadAction<string>) => {
      state.newProduct.name = action.payload;
    },
    changeNewProduct: (state, action: PayloadAction<NewProductCreationPayload>) => {
      const { selectedPermission, fieldName, value } = action.payload;

      const currentNewProduct = { ...state.newProduct };
      const updatedNewProduct = {
        ...currentNewProduct,
        [fieldName]: {
          selectionPermission: selectedPermission.code,
          value: isValueRequired(selectedPermission.code) ? value : undefined,
        },
      };

      state.newProduct = updatedNewProduct;
    },
    clearItemInNewProduct: (state, action: PayloadAction<ClearNewProductCreationPayload>) => {
      const { fieldName } = action.payload;

      const currentNewProduct = { ...state.newProduct };
      const updatedNewProduct = {
        ...currentNewProduct,
        [fieldName]: undefined,
      };

      state.newProduct = updatedNewProduct;
    },
    changeSecondaryPanel: (state, action: PayloadAction<PlannerSecondaryPanelType>) => {
      state.secondaryPanel = action.payload;
    },
    hideSecondaryPanel: (state) => {
      state.secondaryPanel = PlannerSecondaryPanelType.NONE;
    },
    clearNewProduct: (state) => {
      state.newProduct = initialState.newProduct;
    },
  },
});

export const {
  changeSelectedProduct,
  changeNewProduct,
  changeNewProductName,
  changeSecondaryPanel,
  hideSecondaryPanel,
  clearItemInNewProduct,
  clearNewProduct,
} = productManagementSlice.actions;

export default productManagementSlice.reducer;
