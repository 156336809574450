import { Dispatch, SetStateAction, useState } from 'react';
import { CaptionElementProps, NavbarElementProps } from 'react-day-picker';
import DayPicker from 'react-day-picker/DayPicker';
import cx from 'classnames';
import YearMonthForm from 'components/patterns/YearMonthForm';
import Button, { Color, Size } from 'lib/Button';
import arrowLightLeft from 'assets/icons/arrow_light_left.svg';
import arrowLightRight from 'assets/icons/arrow_light_right.svg';
import './DateRangePicker.pcss';
import { RangeSelection } from './constants';

export const formatDate = (date?: Date): string => {
  if (!date) return 'Select';

  const monthNumber = date.getMonth() + 1;

  return `${date.getDate()}/${monthNumber}/${date.getFullYear()}`;
};

type DateRangePickerProps = {
  from: Date;
  setFrom: Dispatch<SetStateAction<Date | undefined>>;
  to: Date;
  setTo: Dispatch<SetStateAction<Date | undefined>>;
};

const DateRangePicker: React.FC<DateRangePickerProps> = ({ from, to, setFrom, setTo }: DateRangePickerProps) => {
  const today = new Date();
  const [month, setMonth] = useState<Date>(new Date(from || today));
  const [editedDate, setEditedDate] = useState<RangeSelection>(RangeSelection.FROM);

  const handleDayClick = (day: Date): void => {
    if (from.getTime() === to.getTime() && day > from && editedDate === RangeSelection.FROM) {
      setFrom(day);
      setTo(day);
      setEditedDate(RangeSelection.TO);
    }

    if (day < from && editedDate !== RangeSelection.FROM) {
      setFrom(day);
      return;
    }

    if (day > to && editedDate !== RangeSelection.TO) {
      setTo(day);
      return;
    }

    if (editedDate === RangeSelection.FROM) {
      setFrom(day);
      setEditedDate(RangeSelection.TO);
    }

    if (editedDate === RangeSelection.TO) {
      setTo(day);
      setEditedDate(RangeSelection.FROM);
    }
  };

  const FIRST_DAY_OF_THE_WEEK = 1;
  const YEARS_BEFORE = 0;
  const YEARS_AFTER = 3;

  const handleYearMonthChange = (newDate: Date): void => setMonth(newDate);

  return (
    <div
      className="DateTimePicker DateTimePicker-range flex flex-col items-center"
      data-test-id="date-range-picker-calendar"
    >
      <div className="flex flex-row w-full px-3">
        <button
          className={cx('flex flex-col w-1/2 border border-neutral-300 rounded-l-md p-2.5 bg-white', {
            'ring-1 border-primary-600 ring-primary-600 bg-primary-50 z-2': editedDate === 'from',
          })}
          onClick={() => setEditedDate(RangeSelection.FROM)}
          type="button"
        >
          <span className="text-essential-primary leading-5">Start date</span>
          <span className="text-essential-tertiary leading-5">{formatDate(from)}</span>
        </button>
        <button
          className={cx('flex flex-col w-1/2 border border-neutral-300 rounded-r-md p-2.5 bg-white', {
            'ring-1 border-primary-600 ring-primary-600 bg-primary-50 z-2': editedDate === 'to',
          })}
          onClick={() => setEditedDate(RangeSelection.TO)}
          type="button"
        >
          <span className="text-essential-primary leading-5">End date</span>
          <span className="text-essential-tertiary leading-5">{formatDate(to)}</span>
        </button>
      </div>
      <DayPicker
        modifiers={{ start: from, end: to }}
        showOutsideDays
        firstDayOfWeek={FIRST_DAY_OF_THE_WEEK}
        selectedDays={[from, { from, to }]}
        disabledDays={{ before: new Date() }}
        month={month}
        onDayClick={handleDayClick}
        captionElement={(props: CaptionElementProps) => (
          <YearMonthForm
            date={month}
            onChange={handleYearMonthChange}
            localeUtils={props.localeUtils}
            yearsBefore={YEARS_BEFORE}
            yearsAfter={YEARS_AFTER}
          />
        )}
        navbarElement={(props: NavbarElementProps) => (
          <div className="absolute w-[320px] flex justify-between">
            <Button
              onClick={() => {
                props.onPreviousClick();
                setMonth(props.previousMonth);
              }}
              svg={arrowLightLeft}
              color={Color.SECONDARY}
              classNames="w-10 h-10 flex items-center justify-center z-20"
              svgClassnames="text-primary w-6 h-6"
            />
            <Button
              onClick={() => {
                props.onNextClick();
                setMonth(props.nextMonth);
              }}
              size={Size.MEDIUM}
              svg={arrowLightRight}
              color={Color.SECONDARY}
              classNames="w-10 h-10 flex items-center justify-center z-20"
              svgClassnames="text-primary w-6 h-6"
            />
          </div>
        )}
      />
    </div>
  );
};

export default DateRangePicker;
