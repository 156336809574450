// eslint-disable-next-line sonarjs/cognitive-complexity
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import {
  DirectSalesIntendedDealLineState,
  DirectSalesStatusOption,
} from 'components/common/types/DirectSalesCampaign.types';
import {
  hidePlannerSecondaryPanel,
  setDirectSalesStates,
  updateTransientLineStates,
} from 'store/dealManagement/reducer';
import { useDispatch, useSelector } from 'react-redux';
import warningSvg from 'assets/icons/warning.svg';
import Button, { Color, Size } from 'lib/Button';
import Modal from 'lib/Modal';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import StatusOption from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/Status/StatusPanel/StatusOption';
import DateTimePicker from 'lib/DateTimePicker';
import {
  unifyTransientLinesStates,
  getStatusOptionLabel,
  transformTransientLinesStatesIntoLinesStates,
} from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/Status/StatusPanel/utils';
import { getCampaignOptionDropDate } from 'store/dealManagement/selectors';
import useDirectSalesPlannerActions from 'components/pages/Planner/hooks/useDirectSalesPlannerActions';
import { isDirectSalesCampaignCloned } from 'components/pages/Planner/hooks/utils/isDirectSalesCampaignCloned';
import { Store } from 'components/common/types/Store.types';
import { getOriginalCampaignStatuses } from 'components/pages/Planner/hooks/utils/directSalesTransientLineUtils';
import { useParams } from 'react-router-dom';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { DirectSalesCampaignStatusProps } from './DirectSalesCampaignStatus.types';

// eslint-disable-next-line sonarjs/cognitive-complexity
const DirectSalesCampaignStatusPanel: React.FC<DirectSalesCampaignStatusProps> = ({ campaignState, options }) => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();
  const { campaignId: originalCampaignId } = useParams();

  const minCampaignDropDate = useSelector(getCampaignOptionDropDate);
  const marketId = useSelector((state: Store) => state.publisher.configuration.marketId);
  const transientLineStates = useSelector((state: Store) => state.dealManagement.directSales.transientLineStates);
  const campaignId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);

  const [expires, setExpires] = useState(campaignState.expires ?? '');
  const [status, setStatus] = useState(campaignState.status);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const { saveCampaignState } = useDirectSalesPlannerActions();

  const onStatusChange = (newStatus: DirectSalesStatusOption): void => setStatus(newStatus);
  const onDateChange = (value: Date): void => setExpires(value);

  const isStatusChange = useMemo(() => {
    if (status === DirectSalesStatusOption.OPTION && !expires) return false;

    return !isEqual(
      {
        status: campaignState.status,
        expires: campaignState.expires ?? '',
      },
      { status, expires },
    );
  }, [campaignState, status, expires]);

  const submitStatusChange = async (): Promise<void> => {
    const state = {
      status,
      ...(expires && status === DirectSalesStatusOption.OPTION ? { expires } : {}),
    };

    await saveCampaignState(state);
  };

  const updateStoreWithTransientStatusUpdates = (
    currentTransientLineStates: DirectSalesIntendedDealLineState,
  ): void => {
    const currentLineExpiry = status === DirectSalesStatusOption.OPTION && expires ? { expires } : {};

    const newTransientLineStates = unifyTransientLinesStates(currentTransientLineStates, status, currentLineExpiry);
    dispatch(updateTransientLineStates(newTransientLineStates));

    const newCampaignLineStates = transformTransientLinesStatesIntoLinesStates(newTransientLineStates);
    dispatch(setDirectSalesStates(newCampaignLineStates));

    dispatch(hidePlannerSecondaryPanel());
  };

  const handleClonedCampaignStatusUpdate = useCallback(async () => {
    if (isEmpty(transientLineStates) || !transientLineStates) {
      const originalStates = await getOriginalCampaignStatuses(
        marketId,
        originalCampaignId ?? campaignId,
        cancelFunctions,
      );

      updateStoreWithTransientStatusUpdates(originalStates.originalCampaignStatusesToIntendedFormat);

      return;
    }

    updateStoreWithTransientStatusUpdates(transientLineStates);
  }, [transientLineStates, status, expires]);

  const handleSubmit = useCallback((): void => {
    if (isDirectSalesCampaignCloned(campaignId)) {
      handleClonedCampaignStatusUpdate();

      return;
    }

    if (status === DirectSalesStatusOption.CANCELLED) {
      setIsCancelModalOpen(true);
      return;
    }

    submitStatusChange();
  }, [handleClonedCampaignStatusUpdate, campaignId, status, expires]);

  return (
    <>
      <PlannerSecondaryPanel
        title="Campaign status"
        onSubmit={handleSubmit}
        onCancel={() => {
          dispatch(hidePlannerSecondaryPanel());
          onStatusChange(campaignState.status);
        }}
        customStyles={{ left: '430px' }}
        isChange={isStatusChange}
        isDisabled={!isStatusChange}
      >
        <div className="flex flex-col space-y-1">
          {options.map((option) => (
            <StatusOption
              onClick={() => setStatus(option)}
              isSelected={status === option}
              text={getStatusOptionLabel(option)}
              key={option}
            >
              {option === DirectSalesStatusOption.OPTION && (
                <DateTimePicker
                  value={new Date(expires)}
                  onChange={onDateChange}
                  disabledDaysBefore={minCampaignDropDate}
                  seconds={59}
                  milliseconds={999}
                />
              )}
            </StatusOption>
          ))}
        </div>
      </PlannerSecondaryPanel>
      <Modal
        dataTestId="planner-cancel-campaign-lines-modal"
        title="Cancel deal lines"
        text="Are you sure you want to cancel all the deal lines? This action cannot be undone."
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        icon={warningSvg}
        actionButtons={
          <>
            <Button
              dataTestId="planner-cancel-campaign-lines-modal-cancel"
              label="Cancel"
              size={Size.MEDIUM}
              color={Color.SECONDARY}
              onClick={() => setIsCancelModalOpen(false)}
            />
            <Button
              dataTestId="planner-cancel-campaign-lines-modal-confirm"
              label="Cancel campaign lines"
              size={Size.MEDIUM}
              color={Color.DANGER}
              onClick={() => {
                setIsCancelModalOpen(false);
                submitStatusChange();
              }}
            />
          </>
        }
      />
    </>
  );
};

export default DirectSalesCampaignStatusPanel;
